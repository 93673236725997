import React, { Component } from 'react';

// 导入第三方组件
import { Form, Input, message, Button } from 'antd'

// 导入工具类
import { Custom } from '../../utils/request'

const Item = Form.Item

class CustomName extends Component {
  state = {
    custom: {}
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const form = this.props.form
    form.validateFields((err, values) => {
      if (!err) {

        // 处理表单数据
        for (const key in values) {
          const v = values[key]
          if (!v) {
            values[key] = ''
          } else {
            values[key] = v.split(' ').join('')
          }
        }

        Custom.set(values)
          .then(res => {
            const { success, msg } = res.data
            if (success) {
              message.success('设置成功!', 3)
              window.localStorage.setItem('custom', JSON.stringify(values))
              this.setState({ custom: values }, form.resetFields())
            } else {
              message.error(msg, 3)
            }
          })
          .catch(err => {
            console.log(err);
            message.error('服务器错误!', 3)
          })
      }
    })
  }

  componentDidMount() {
    const custom = window.localStorage.getItem('custom') || '{}'
    this.setState({ custom: JSON.parse(custom) })
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { custom } = this.state
    return (
      <div id='CustomName'>
        <Form onSubmit={this.handleSubmit} layout='inline'>
          <Item label={'学校级名称'}>
            {getFieldDecorator('root', {
              initialValue: custom.root || ''
            })(
              <Input placeholder="默认为学校" />
            )}
          </Item>
          <Item label={'校区级名称'}>
            {getFieldDecorator('department', {
              initialValue: custom.department || ''
            })(
              <Input placeholder="默认为校区" />
            )}
          </Item>
          <Item label={'团队级名称'}>
            {getFieldDecorator('team', {
              initialValue: custom.team || ''
            })(
              <Input placeholder="默认为团队" />
            )}
          </Item>
          <Item label={'用户职称'}>
            {getFieldDecorator('user', {
              initialValue: custom.user || ''
            })(
              <Input placeholder="默认为教师" />
            )}
          </Item>
          <Item>
            <Button type="primary" htmlType="submit">提交</Button>
          </Item>
        </Form>
      </div>
    );
  }
}

CustomName = Form.create({})(CustomName);

export default CustomName;