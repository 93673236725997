import React, { Component } from 'react';

// 导入第三方组件
import { Form, Input, Button, message, Table, Popconfirm } from 'antd'
import { Link } from 'react-router-dom'

// 导入工具类
import { Team } from '../../../utils/request'

class TeamManage extends Component {
  state = {
    teamRows: 0, // 团队总条数
    dataSource: [], // 列表数据
    pages: 1, // 当前页数
    pageSize: 10, // 每页显示条数
    columns: [ // 表头
      { title: '团队名称', dataIndex: 'name' },
      {
        title: '管理', dataIndex: 'manage',
        render: (text, record) => (
          this.state.dataSource.length ? (
            <div>
              <Link to={`/team/update/${record.key}`} style={{ display: 'inline-block', marginRight: '10px' }}>修改</Link>
              <Popconfirm title="确定删除?" okText="是" cancelText="否" onConfirm={() => this.deleteTeam(record.key)}>
                <span style={{ display: 'inline-block', color: '#1890ff', cursor: 'pointer' }}>删除</span>
              </Popconfirm>
            </div>
          ) : null
        ),
      }
    ],
  }

  // 添加团队
  addTeam = (e) => {
    e.preventDefault();
    const that = this
    const form = this.props.form
    form.validateFields((err, values) => {
      if (!err) {
        values.name = values.name.split(' ').join('')
        if (!values.name) {
          message.warn('名称不能为空!')
          return form.resetFields()
        }

        // 发送数据
        Team.add(values)
          .then(function (res) {
            if (res.data.success) {
              message.success('添加成功!', 3);
              form.resetFields()
              that.setState({ pages: 1 }, that.getTeamList(1))
            } else {
              message.error(res.data.msg, 3)
            }
          })
          .catch(function (err) {
            console.log(err);
            message.error('程序错误!', 3);
          });
      }
    });
  }

  // 删除团队
  deleteTeam = (id) => {
    Team.delete({ id })
      .then(res => {
        const { success, msg } = res.data
        if (success) {
          message.success('删除成功!', 3)
          this.setState({pages: 1}, this.getTeamList(1))
        } else {
          message.error(msg, 3)
        }
      })
      .catch(err => {
        console.log(err);
        message.error('程序错误!', 3)
      })
  }

  // 获取团队列表
  getTeamList = (pages = 1) => {
    Team.list({ pages })
      .then(res => {
        const { resData, rows, msg, success } = res.data
        if (success) {
          const dataSource = []
          resData.forEach(item => {
            dataSource.push({ name: item.name, key: item._id })
          })
          this.setState({ dataSource, teamRows: rows })
        } else {
          message.error(msg, 3)
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  // 换页
  changePage = (pages) => {
    this.setState({ pages }, this.getTeamList(pages))
  }

  componentDidMount() {
    this.getTeamList(1)
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { pages, pageSize, teamRows, dataSource, columns } = this.state
    return (
      <div id="TeamManage">
        <Form onSubmit={this.addTeam} layout='inline' style={{ paddingBottom: '20px' }}>

          {/* 团队名称 */}
          <Form.Item>
            {getFieldDecorator('name', {
              rules: [{ required: true, message: '请输入团队名称!' }],
            })(
              <Input placeholder="团队名称" />
            )}
          </Form.Item>

          {/* 添加按钮 */}
          <Form.Item>
            <Button type="primary" htmlType="submit">添加</Button>
          </Form.Item>
        </Form>

        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={{
            current: pages,
            pageSize,
            total: teamRows,
            onChange: this.changePage
          }}
        />
      </div>
    );
  }
}
TeamManage = Form.create({})(TeamManage);

export default TeamManage;