import React from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom'

/* 引入组件 */
import Layout from './components/Layout'
import Login from './components/Login'

function App() {
  return (
    <Router>
      <Switch>
        <Route path='/login' component={Login} />
        <Route path='/' component={Layout} />
      </Switch>
    </Router>
  );
}

export default App;
