import React, { Component } from 'react';

// 导入第三方组件
import { Form, Input, Button, message, Cascader, Upload, Icon, Modal } from 'antd'

// 导入工具类
import { IntegType, Api, IntegDetail } from '../../../utils/request'

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

class IntegApply extends Component {
  state = {
    fileList: [], // 显示在页面的文件名列表
    fileNameList: [], // 后台文件路径列表
    types: [], // 积分类型
    value: 0, // 积分分数
    auditType: 0, // 审核类型  
    previewImage: '',
    previewVisible: false,
  }

  // 申请积分
  apply = (e) => {
    e.preventDefault();
    const form = this.props.form
    form.validateFields((err, values) => {
      if (!err) {
        const _now = Date.now()
        const now = this.state.now
        if(now && ((_now - now) < 1000)){
          return
        }
        this.state.now = _now

        // 如果没有选择二级积分类型 返回
        if (values.type.length !== 2) {
          message.warn('请选择积分类型!', 3)
        } else {
          const { fileList } = this.state
          const attachment = fileList.map(file => file.response.resData)

          IntegDetail.apply({ ...values, attachment })
            .then(res => {
              const { success, msg } = res.data
              if (success) {
                message.success('提交成功!', 3)
                // 重置表单
                form.resetFields()

                // 获取垃圾文件对象
                const upload = JSON.parse(window.localStorage.getItem('upload')) || {}

                // 如果 有垃圾文件
                if (upload.integral && upload.integral.length && attachment.length) {

                  // 从垃圾文件数组中删除已上传的文件路径
                  attachment.forEach(item => {
                    upload.integral.splice(upload.integral.indexOf(item), 1)
                  })
                }

                window.localStorage.setItem('upload', JSON.stringify(upload))

                // 更新视图数据
                this.setState({ value: 0, auditType: 0, fileList: [] })
              } else {
                message.error(msg, 3)
              }
            })
        }
      }
    });
  }

  // 获取积分类型列表
  getTypeList = () => {
    IntegType.list({ pages: 0 })
      .then(res => {
        const { resData, success } = res.data
        if (success) {
          const types = []
          resData.forEach(item1 => {
            if (!item1.level) { // 顶级类型
              types.push({ value: item1._id, label: item1.name, children: [] })
            } else { // 二级类型
              types.forEach(item2 => {
                if (item2.label === item1.fid.name) {
                  item2.children.push({ value: item1._id, label: item1.name, })
                }
              })
            }
          })
          
          this.setState({ types })
        }
      })
      .catch(err => {
        console.log(err);
        message.error('积分类型列表请求失败!')
      })
  }

  // 判断图片是否过大
  beforeUpload = (file) => {
    if (file.size > 1024 * 1024) {
      message.warn('图片大小不能超过1M!', 3)
      return false
    }
  }

  // 处理文件状态变化
  handleChange = ({ file, fileList }) => {
    if (file.status === 'done' && file.response.success) {
      // 上传成功时 获取文件缓存对象
      const upload = JSON.parse(window.localStorage.getItem('upload')) || {}
      upload.integral || (upload.integral = [])

      // 把文件路径保存到缓存中
      upload.integral.push(file.response.resData)
      window.localStorage.setItem('upload', JSON.stringify(upload))
    }

    if (file.status === 'error') {
      message.error('图片上传失败!', 3)
    }

    this.setState({ fileList })
  }

  // 预览图片
  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  // 关闭预览
  handleCancel = () => this.setState({ previewVisible: false });

  changeType = (value) => {
    if (value.length === 2) {
      IntegType.info({ id: value[1] })
        .then(res => {
          const { success, resData } = res.data
          if (success) {
            this.setState({ value: resData.value, auditType: resData.auditType })
          }
        })
        .catch(err => {
          console.log(err);
        })
    }
  }

  componentDidMount() {
    this.getTypeList()
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { value, types, auditType, fileList, previewVisible, previewImage } = this.state
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">上传附件</div>
      </div>
    );
    return (
      <div id="IntegApply">
        <Form onSubmit={this.apply}>

          {/* 积分类型 */}
          <Form.Item label='积分类型' style={{ width: '200px' }}>
            {getFieldDecorator('type', {
              rules: [{ required: true, message: '请选择积分类型!' }],
            })(
              <Cascader options={types} onChange={this.changeType} placeholder="积分类型" />
            )}
          </Form.Item>

          {/* 积分分数 */}
          <Form.Item label='积分分数' style={{ width: '200px' }}>
            <Input type={'number'} disabled={true} value={value} />
          </Form.Item>

          {/* 数量 */}
          <Form.Item label='申请数量' style={{ width: '200px' }}>
            {getFieldDecorator('count', {
              rules: [{ required: true, message: '请选择申请数量!' }], initialValue: 1
            })(
              <Input type={'number'} placeholder='申请数量' />
            )}
          </Form.Item>

          {/* 审核方式 */}
          <Form.Item label='审核方式' style={{ width: '200px' }}>
            <Input disabled={true} value={auditType ? '手动' : '自动'} />
          </Form.Item>

          {/* 附件上传 */}
          <Form.Item>
            <Upload
              action={Api + '/integdetail/upload'}
              accept=".jpg,.png"
              beforeUpload={this.beforeUpload}
              onChange={this.handleChange}
              onPreview={this.handlePreview}
              fileList={fileList}
              listType="picture-card"
              withCredentials={true}
            >
              {fileList.length >= 5 ? null : uploadButton}
            </Upload>
            <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
              <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>

          </Form.Item>

          {/* 备注 */}
          <Form.Item label='备注说明' style={{ width: '500px' }}>
            {getFieldDecorator('remarks', {
            })(
              <Input.TextArea placeholder='备注说明'></Input.TextArea>
            )}
          </Form.Item>

          {/* 提交按钮 */}
          <Form.Item>
            <Button type="primary" htmlType="submit">提交申请</Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

IntegApply = Form.create({})(IntegApply);

export default IntegApply;