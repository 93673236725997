import React, { Component } from 'react';

// 导入第三方组件
import { message, Table, Icon, Modal, Popconfirm } from 'antd'

// 导入工具类
import { Host, IntegDetail } from '../../../utils/request'

class MyIntegral extends Component {
  state = {
    rows: 0, // 团队总条数
    dataSource: [], // 列表数据
    pages: 1, // 当前页数
    pageSize: 10, // 每页显示条数
    showPreview: false,
    previewContent: '',
    columns: [ // 表头
      { title: '顶级积分类型', dataIndex: 'firstType' },
      { title: '二级积分类型', dataIndex: 'secondType' },
      { title: '单项积分', dataIndex: 'singleValue' },
      { title: '申请数量', dataIndex: 'count' },
      { title: '总积分', dataIndex: 'totalValue' },
      { title: '申请时间', dataIndex: 'createdTime' },
      { title: '审核时间', dataIndex: 'auditTime' },
      { title: '审核人', dataIndex: 'audit' },
      { title: '备注', dataIndex: 'remarks' },
      { title: '审核意见', dataIndex: 'opinion' },
      { title: '积分类型', dataIndex: 'type' },
      { title: '审核类型', dataIndex: 'auditType' },
      {
        title: '附件', dataIndex: 'attachment',
        render: (text, record) => {
          if (/\.mp4$/.test(text[0])) {
            return (
              <Icon type="video-camera" onClick={this.preview.bind(this, text[0])} title='点击查看视频' />
            )
          } else {
            return text.map(path => {
              return <img src={Host + path} key={path} width='50' onClick={this.preview.bind(this, path)} alt='' title='点击查看大图' />
            })
          }
        }
      },
      { title: '审核状态', dataIndex: 'status' },
      {
        title: '撤销', dataIndex: 'manage',
        render: (text, record) => (
          text ? <Popconfirm title="此操作不可恢复,是否确定撤销?" okText="是" cancelText="否" onConfirm={() => this.revokeApply(record.key)}>
            <span style={{ color: '#1890ff', cursor: 'pointer' }}>撤销</span>
          </Popconfirm> : null
        )
      },
    ],
  }

  preview = (path) => {
    this.setState({
      showPreview: true,
      previewContent: /\.mp4$/.test(path) ?
        (<video controls width='800' height='600'>
          <source src={Host + path}
            type="video/mp4"></source>
        </video>) :
        (<img src={Host + path} alt='' height='600' />)
    })
  }

  closePreview = () => {
    this.setState({ showPreview: false })
  }

  getIntegList = (pages = 1) => {
    const that = this
    IntegDetail.mine({ pages })
      .then(res => {
        const { resData, rows, msg, success } = res.data
        if (success) {
          const dataSource = resData.map(item => {
            return {
              key: item._id,
              firstType: item.firstType.name,
              secondType: item.secondType.name,
              singleValue: item.singleValue,
              count: item.count,
              totalValue: item.totalValue,
              createdTime: new Date(item.createdTime).toLocaleString(),
              auditTime: item.auditTime && new Date(item.auditTime).toLocaleString(),
              audit: item.audit ? item.audit.name : '',
              remarks: item.remarks,
              opinion: item.opinion || '',
              type: item.type ? '加分' : '申请',
              auditType: item.auditType ? '手动' : '自动',
              attachment: item.attachment,
              manage: item.status === 1,
              status: (
                item.status ?
                  item.status === 1 ?
                    '待审' : item.status === 2 ?
                      '已审' : '不批准' :
                  '作废'
              )
            }
          })

          that.setState({ dataSource, rows })
        } else {
          message.error(msg, 3)
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  revokeApply = (id) => {
    IntegDetail.revokeApply({ id })
      .then(res => {
        const { success, msg } = res.data
        if (success) {
          message.success('撤销成功!', 3)
          this.setState({ pages: 1 }, this.getIntegList(1))
        } else {
          message.error(msg, 3)
        }
      })
      .catch(err => {
        console.log(err);
        message.error('程序错误!', 3)
      })
  }

  changePage = (pages) => {
    this.setState({ pages }, this.getIntegList(pages))
  }

  componentDidMount() {
    this.getIntegList(1)
  }

  render() {
    const { pages, pageSize, rows, dataSource, columns, showPreview } = this.state
    return (
      <div id="MyIntegral">
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={{
            current: pages,
            pageSize,
            total: rows,
            onChange: this.changePage
          }}
        />

        <Modal
          footer={null}
          width={1000}
          style={{ textAlign: 'center' }}
          onCancel={this.closePreview}
          visible={showPreview}
        >
          {this.state.previewContent}
        </Modal>
      </div>
    );
  }
}

export default MyIntegral;