import React, { Component } from 'react';
import './index.scss'

// 导入第三方组件
import { Form, Select, Input, Button, message } from 'antd'

// 导入工具类
import { Department } from '../../../utils/request'

class DepartmentUpdate extends Component {
  state = {
    id: this.props.match.params.id,
    custom: {},
    departmentInfo: {}
  }

  getDepartmentInfo = () => {
    Department.info({ id: this.state.id })
      .then(res => {
        const { success, msg, resData } = res.data
        if (success) {
          this.setState({ departmentInfo: resData || {} })
        } else {
          message.error(msg, 3)
        }
      })
      .catch(err => {
        console.log(err);
      })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const that = this
    const form = this.props.form
    form.validateFields((err, values) => {
      if (!err) {
        if (!values.name.trim()) {
          return form.resetFields()
        }

        Department.update({ ...values, id: that.state.id })
          .then(res => {
            const { success, msg } = res.data
            if (success) {
              message.success('修改成功!', 3)
            } else {
              message.error(msg, 3)
            }
            form.resetFields()
          })
          .catch(err => {
            console.log(err);
          })
      }
    })
  }

  componentDidMount() {
    const custom = window.localStorage.getItem('custom') || '{}'
    this.setState({ custom: JSON.parse(custom) })
    this.getDepartmentInfo()
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { Option } = Select
    const { custom, departmentInfo } = this.state
    return (
      <div id='DepartmentUpdate'>
        <Form onSubmit={this.handleSubmit}>
          <Form.Item style={{ float: 'left', marginRight: '20px' }}>
            {getFieldDecorator('name', {
              rules: [{ required: true, message: `${custom.department || '校区'}名称不能为空!` }],
              initialValue: departmentInfo.name || ''
            })(
              <Input placeholder={`${custom.department || '校区'}名称`} />
            )}
          </Form.Item>
          <Form.Item style={{ float: 'left', marginRight: '20px' }}>
            {getFieldDecorator('all', {
              initialValue: departmentInfo.all || 0
            })(
              <Select>
                <Option value={0}>本{custom.department || '校区'}</Option>
                <Option value={1}>全部</Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item style={{ float: 'left', marginRight: '20px' }}>
            <Button type='primary' htmlType='submit'>确认修改</Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

DepartmentUpdate = Form.create({})(DepartmentUpdate);

export default DepartmentUpdate;