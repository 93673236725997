import React, { Component } from 'react';
import { Form, DatePicker, Button, Input, message } from 'antd'

// 配置日期控件语言
import { LocaleProvider } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import { IntegDetail } from '../../../utils/request';
moment.locale('zh-cn');

class IntegDelete extends Component {
  state = {
    startTime: '',
    endTime: ''
  }

  startTimeChange = (date, dateString) => {
    this.setState({ startTime: dateString })
  }

  endTimeChange = (date, dateString) => {
    this.setState({ endTime: dateString })
  }

  delete = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (err) {
        console.log(err);
        return message.error('程序错误!', 3)
      }
      const { startTime, endTime } = this.state
      if (!startTime) {
        return message.warn('请选择起始时间!')
      }
      if (!endTime) {
        return message.warn('请选择截止时间!')
      }

      values.startTime = startTime
      values.endTime = endTime

      IntegDetail.delete(values)
        .then(res => {
          const { success, msg } = res.data
          if (success) {
            message.success(msg, 3)
          } else {
            message.error(msg, 3)
          }
        })
        .catch(err => {
          console.log(err);
          message.error('程序错误!', 3)
        })

    })
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div id='IntegDelete'>
        <LocaleProvider locale={zh_CN}>
          <Form onSubmit={this.delete}>
            <Form.Item label='起始时间'>
              <DatePicker onChange={this.startTimeChange} />
            </Form.Item>

            <Form.Item label='截止时间'>
              <DatePicker onChange={this.endTimeChange} />
            </Form.Item>

            <Form.Item label='输入密码'>
              {getFieldDecorator('pwd', {
                rules: [{ required: true, message: '请输入密码确认!' }]
              })(
                <Input placeholder='请输入密码!' style={{ width: '200px' }} />
              )}
            </Form.Item>

            <Form.Item>
              <Button type='primary' htmlType='submit'>确定删除</Button>
            </Form.Item>
          </Form>
        </LocaleProvider>
      </div>
    );
  }
}

IntegDelete = Form.create({})(IntegDelete);

export default IntegDelete;