import React, { Component } from 'react';

// 导入第三方组件
import { Form, Input, Button, message, Cascader, Select, DatePicker } from 'antd'

// 导入工具类
import { IntegType, IntegDetail, User, Team } from '../../../utils/request'

import { LocaleProvider } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');

class AddInteg extends Component {
  state = {
    types: [], // 积分类型
    custom: {},
    personalOrTeam: 0, // 个人加分还是团队加分 默认 0 -- 个人加分
    teamList: [],
    userList: [],
    value: 0,
    createdTime: '',
  }

  // 申请积分
  add = (e) => {
    e.preventDefault();
    const form = this.props.form
    form.validateFields((err, values) => {
      if (!err) {
        // 如果没有选择二级积分类型 返回
        if (values.types.length !== 2) {
          message.warn('请选择积分类型!', 3)
        } else {
          let { createdTime } = this.state

          IntegDetail.add({ ...values, createdTime })
            .then(res => {
              const { success, msg } = res.data
              if (success) {
                message.success('提交成功!', 3)
                // 重置表单
                form.resetFields()

                // 更新视图数据
                this.setState({ value: 0 })
              } else {
                message.error(msg, 3)
              }
            })
        }
      }
    });
  }

  // 获取积分类型列表
  getTypeList = () => {
    IntegType.list({ pages: 0 })
      .then(res => {
        const { resData, success } = res.data
        if (success) {
          const types = []
          resData.forEach(item1 => {
            if (!item1.level) { // 顶级类型
              if (item1.special) {
                return
              } else {
                types.push({ value: item1._id, label: item1.name, children: [] })
              }
            } else { // 二级类型
              if (item1.fid.special) {
                return
              } else {
                types.forEach(item2 => {
                  if (item2.label === item1.fid.name) {
                    item2.children.push({ value: item1._id, label: item1.name, })
                  }
                })
              }
            }
          })

          this.setState({ types })
        }
      })
      .catch(err => {
        console.log(err);
        message.error('积分类型列表请求失败!')
      })
  }

  handleAddTypeChange = (value) => {
    if (value) {
      // 请求团队列表
      this.getTeamList({ pages: 0 })
    } else {
      // 请求用户列表
      this.getUserList({ pages: 0, used: true })
    }
    this.setState({ personalOrTeam: value })
  }

  getTeamList = (opt) => {
    Team.list(opt)
      .then(res => {
        const { success, msg, resData } = res.data
        if (success) {
          this.setState({ teamList: resData })
        } else {
          message.error(msg, 3)
        }
      })
      .catch(err => {
        console.log(err);
        message.error('程序错误!', 3)
      })
  }

  getUserList = (opt) => {
    User.userList(opt)
      .then(res => {
        const { success, msg, resData } = res.data
        if (success) {
          this.setState({ userList: resData })
        } else {
          message.error(msg, 3)
        }
      })
      .catch(err => {
        console.log(err);
        message.error('程序错误!', 3)
      })
  }

  changeType = (value) => {
    if (value.length === 2) {
      IntegType.info({ id: value[1] })
        .then(res => {
          const { success, resData } = res.data
          if (success) {
            this.setState({ value: resData.value })
          }
        })
        .catch(err => {
          console.log(err);
        })
    }
  }

  onDateChange = (date, dateString) => {
    this.setState({ createdTime: dateString })
  }

  componentDidMount() {
    const custom = window.localStorage.getItem('custom') || '{}'
    this.setState({ custom: JSON.parse(custom) })
    this.getTypeList()
    this.getUserList({ pages: 0, used: true })
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { value, types, custom, userList, teamList, personalOrTeam } = this.state
    const { Option } = Select
    return (
      <div id="AddInteg">
        <Form onSubmit={this.add}>

          {/* 加分类型 */}
          <Form.Item label='加分类型' style={{ width: '200px' }}>
            <Select defaultValue={0} onChange={this.handleAddTypeChange}>
              <Option value={0}>个人加分</Option>
              <Option value={1}>团队加分</Option>
            </Select>
          </Form.Item>

          {/* 用户列表 */}
          {!personalOrTeam ? (
            <Form.Item label={`选择${custom.user || '教师'}`} style={{ width: '200px' }}>
              {getFieldDecorator('apply', {
                initialValue: userList.length ? userList[0]._id : '',
                rules: [{ required: true, message: `请选择${custom.user || '教师'}` }]
              })(
                <Select>
                  {userList.map(user => {
                    return <Option key={user._id} value={user._id}>{user.name}</Option>
                  })}
                </Select>
              )}
            </Form.Item>
          ) : null}

          {/* 团队列表 */}
          {personalOrTeam ? (
            <Form.Item label={`选择${custom.team || '团队'}`} style={{ width: '200px' }}>
              {getFieldDecorator('team', {
                initialValue: teamList.length ? teamList[0]._id : '',
                rules: [{ required: true, message: `请选择${custom.team || '团队'}` }]
              })(
                <Select>
                  {teamList.map(team => {
                    return <Option key={team._id} value={team._id}>{team.name}</Option>
                  })}
                </Select>
              )}
            </Form.Item>
          ) : null}


          {/* 积分类型 */}
          <Form.Item label='积分类型' style={{ width: '200px' }}>
            {getFieldDecorator('types', {
              rules: [{ required: true, message: '请选择积分类型!' }],
            })(
              <Cascader options={types} onChange={this.changeType} placeholder="积分类型" />
            )}
          </Form.Item>

          {/* 积分分数 */}
          <Form.Item label='积分分数' style={{ width: '200px' }}>
            <Input type={'number'} disabled={true} value={value} />
          </Form.Item>

          {/* 数量 */}
          <Form.Item label='积分数量' style={{ width: '200px' }}>
            {getFieldDecorator('count', {
              rules: [{ required: true, message: '请选择积分数量!' }], initialValue: 1
            })(
              <Input type={'number'} placeholder='积分数量' />
            )}
          </Form.Item>

          {/* 积分产生时间 */}
          <Form.Item label='产生时间'>
            <LocaleProvider locale={zh_CN}>
              <DatePicker onChange={this.onDateChange} />
            </LocaleProvider>
          </Form.Item>

          {/* 备注信息 */}
          <Form.Item label='备注信息' style={{ width: '300px' }}>
            {getFieldDecorator('remarks', {})(
              <Input placeholder='备注信息' />
            )}
          </Form.Item>

          {/* 提交按钮 */}
          <Form.Item>
            <Button type="primary" htmlType="submit">提交申请</Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

AddInteg = Form.create({})(AddInteg);

export default AddInteg;