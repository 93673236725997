import React, { Component } from 'react';
import './index.scss'

// 导入第三方组件
import { HashRouter as Router, Route, Redirect, Switch } from 'react-router-dom'
import { message } from 'antd'

// 导入自定义组件
import Hd from '../Common/Hd' // 公共头
import Ft from '../Common/Ft' // 公共尾
import SideNav from '../Common/SideNav' // 公共左侧菜单

// 路由
import routes from '../../routes/routes'

// 导入工具类
import { User, Custom } from '../../utils/request'

class Layout extends Component {
  state = { userInfo: {}, custom: null, isLogin: null }

  // 获取并保存用户信息
  setUserInfo = () => {
    User.personalInfo()
      .then(res => {
        const { success, msg, resData, remarks } = res.data
        if (success) {
          // 请求成功 保存到state
          if (remarks) {
            message.warn(remarks, 0)
          }
          this.setState({
            userInfo: resData,
            isLogin: true,
            custom: resData.identity < 1 && {}
          })
          window.localStorage.setItem('userInfo', JSON.stringify({
            name: resData.name,
            identity: resData.identity,
            avatar: resData.avatar,
            all: resData.department && resData.department.all
          }))
          if (resData.identity > 0) {
            // 获取自定义名称
            Custom.get()
              .then(res => {
                const { success, resData } = res.data
                if (success) {
                  // 请求成功 保存到state
                  this.setState({ custom: res.data.resData || {} })
                  window.localStorage.setItem('custom', JSON.stringify({
                    root: resData && resData.root,
                    department: resData && resData.department,
                    team: resData && resData.team,
                    user: resData && resData.user
                  }))
                }
              })
              .catch(err => {
                // 请求错误
                console.log(err);
              })
          }
        } else {
          message.error(msg, 3)
          this.setState({ isLogin: false })
        }
      })
      .catch(err => {
        // 请求错误
        console.log(err);
        this.setState({ isLogin: false })
      })
  }

  componentDidMount() {
    // 组件加载完成时 获取并保存用户信息
    this.setUserInfo()
  }

  render() {
    const { isLogin, userInfo, custom } = this.state
    if (isLogin) {
      // 已登录
      return (
        <div id='Layout'>
          {/* 当自定义名称请求完成时 加载头部 */}
          {custom ? <Hd userInfo={userInfo} custom={custom} /> : null}

          <div id="Body" className='clearfix'>
            {/* 当自定义名称请求完成时 加载侧边菜单 */}
            {custom ? <SideNav identity={userInfo.identity} custom={custom} /> : null}

            {/* 主体 */}
            <div id="Content">
              <Router>
                <Switch>
                  {/* 路由列表 */}
                  {routes.map((route, index) => {
                    return <Route path={route.path} component={route.component} key={index} exact={route.exact ? true : false} />
                  })}

                  {/* 未匹配到的路由 重定向到首页 */}
                  <Redirect to="/" />
                </Switch>
              </Router>
            </div>
          </div>

          {/* 底部 */}
          <Ft />
        </div >
      );
    } else if (isLogin === false) {
      // 未登录 或 登录信息已过期 重定向到登录页
      return <Redirect to='/login' />
    } else {
      return ''
    }
  }
}

export default Layout;