import React, { Component } from 'react';

// 导入第三方组件
import { Button, Tree, Modal, message } from 'antd'

// 导入工具类
import { IntegType } from '../../../utils/request'

class IntegTypeTemplate extends Component {
  state = {
    typeTree: [], // 积分类型树
    modalVisible: false // 控制对话框显示
  }

  // 设置积分类型树
  setTypeTree = () => {
    const that = this
    IntegType.template()
      .then(res => {
        const { resData } = res.data
        const typeTree = []
        resData.forEach(item => {

          if (!item.level) { // 如果是顶级分类
            typeTree.push({ title: item.name, id: item._id, children: [] })
          } else {
            typeTree.forEach((i, index) => {
              if (i.id === item.fid) { // 找父级
                typeTree[index].children.push({ title: item.name })
              }
            })
          }
        })
        that.setState({ typeTree })
      })
      .catch(err => {
        console.log(err);
      })
  }

  // 显示对话框
  showModal = (e) => {
    this.setState({ modalVisible: true })
  }

  handleCancel = () => {
    this.setState({ modalVisible: false })
  }

  handleOk = () => {
    IntegType.import()
      .then(res => {
        if (res.data.success) {
          this.setState({ modalVisible: false })
          message.success('导入成功!')
        } else {
          message.error(res.data.msg, 3)
        }
      })
      .catch(err => {
        console.log(err);
        message.error('程序错误!', 3)
      })
  }

  componentDidMount() {
    this.setTypeTree()
  }


  render() {
    const { TreeNode } = Tree
    const { typeTree, modalVisible } = this.state
    return (
      <div id='IntegTypeTemplate'>
        <Button onClick={this.showModal} type='primary' style={{ display: 'inline-block', marginBottom: '20px' }}>导入模板</Button>
        <Tree>
          {typeTree.map((item, index) => {
            return (<TreeNode title={item.title} key={index} selectable={false}>
              {item.children.map((i, index) => {
                return <TreeNode title={i.title} key={index} selectable={false} />
              })}
            </TreeNode>)
          })}
        </Tree>
        <Modal
          title="导入积分类型模版"
          visible={modalVisible}
          okText='确认'
          cancelText='取消'
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <p>导入模版会清空现有模版,确定?</p>
        </Modal>
      </div>
    );
  }
}

export default IntegTypeTemplate;