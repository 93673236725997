import Axios from 'axios'

// export const Host = 'http://59.110.154.254:6001'
// export const Host = 'http://localhost:6001'
// export const Host = 'http://39.97.190.54:6001'
export const Host = 'https://api.lnhbjy.com'

export const Api = Host + '/api'

Axios.defaults.baseURL = Api
Axios.defaults.withCredentials = true
Axios.defaults.method = 'POST'

// 用户类请求
export const User = {
  // 添加用户
  addUser(opt) {
    return Axios({
      data: opt,
      url: '/user/add',
    })
  },

  // 删除用户
  deleteUser(opt) {
    return Axios({
      data: opt,
      url: '/user/delete',
    })
  },

  // 用户列表
  userList(opt) {
    return Axios({
      data: opt,
      url: '/user/list',
    })
  },

  // 个人信息
  personalInfo() {
    return Axios({
      url: '/user/personalinfo',
    })
  },

  // 更新个人信息
  personalUpdate(opt) {
    return Axios({
      url: '/user/personalupdate',
      data: opt
    })
  },

  // 子级用户信息
  childInfo(opt) {
    return Axios({
      url: '/user/childinfo',
      data: opt
    })
  },
  // 修改子级用户信息
  updateChild(opt) {
    return Axios({
      url: '/user/updatechild',
      data: opt
    })
  },

  // 登陆
  login(opt) {
    return Axios({
      url: '/user/login',
      data: opt
    })
  },

  // 注销
  logout() {
    return Axios({
      url: '/user/logout',
    })
  },

  // 团队成员
  teamMember() {
    return Axios({
      url: '/user/teammember'
    })
  }
}

// 校区类
export const Department = {
  // 校区列表
  list(opt) {
    return Axios({
      url: '/department/list',
      data: opt
    })
  },

  // 获取校区信息
  info(opt) {
    return Axios({
      url: '/department/info',
      data: opt
    })
  },

  // 更新校区信息
  update(opt) {
    return Axios({
      url: '/department/update',
      data: opt
    })
  },

  // 添加校区
  add(opt) {
    return Axios({
      url: '/department/add',
      data: opt
    })
  },

  // 删除校区
  delete(opt) {
    return Axios({
      url: '/department/delete',
      data: opt
    })
  }
}

// 团队类
export const Team = {
  // 团队列表
  list(opt) {
    return Axios({
      url: '/team/list',
      data: opt
    })
  },

  // 添加团队
  add(opt) {
    return Axios({
      url: '/team/add',
      data: opt
    })
  },

  // 删除团队
  delete(opt) {
    return Axios({
      url: '/team/delete',
      data: opt
    })
  },

  // 团队信息
  info(opt) {
    return Axios({
      url: '/team/info',
      data: opt
    })
  },

  // 更新团队信息
  update(opt) {
    return Axios({
      url: '/team/update',
      data: opt
    })
  }
}

// 积分类型
export const IntegType = {
  // 积分类型列表
  list(opt) {
    return Axios({
      url: '/integtype/list',
      data: opt
    })
  },

  // 添加
  add(opt) {
    return Axios({
      url: '/integtype/add',
      data: opt
    })
  },

  // 删除
  delete(opt) {
    return Axios({
      url: '/integtype/delete',
      data: opt
    })
  },

  // 修改
  update(opt) {
    return Axios({
      url: '/integtype/update',
      data: opt
    })
  },

  // 作废
  disabled(opt) {
    return Axios({
      url: '/integtype/disabled',
      data: opt
    })
  },

  // 积分类型信息
  info(opt) {
    return Axios({
      url: '/integtype/info',
      data: opt
    })
  },

  // 类型模版
  template() {
    return Axios({
      url: '/integtype/template'
    })
  },

  // 导入模板
  import() {
    return Axios({
      url: '/integtype/import'
    })
  }
}

// 积分详情
export const IntegDetail = {
  // 申请积分
  apply(opt) {
    return Axios({
      url: '/integdetail/apply',
      data: opt
    })
  },

  // 撤销申请
  revokeApply(opt) {
    return Axios({
      url: '/integdetail/revokeapply',
      data: opt
    })
  },

  // 积分加分
  add(opt) {
    return Axios({
      url: '/integdetail/add',
      data: opt
    })
  },

  // 清空积分
  delete(opt) {
    return Axios({
      url: '/integdetail/delete',
      data: opt
    })
  },

  // 积分详情
  info(opt) {
    return Axios({
      url: '/integdetail/info',
      data: opt
    })
  },

  // 修改积分
  update(opt) {
    return Axios({
      url: '/integdetail/update',
      data: opt
    })
  },

  // 全部积分列表
  list(opt) {
    return Axios({
      url: '/integdetail/list',
      data: opt
    })
  },

  // 权限范围内积分列表
  manage(opt) {
    return Axios({
      url: '/integdetail/manage',
      data: opt
    })
  },

  // 我的积分列表
  mine(opt) {
    return Axios({
      url: '/integdetail/mine',
      data: opt
    })
  },

  // 审核积分
  audit(opt) {
    return Axios({
      url: '/integdetail/audit',
      data: opt
    })
  },

  // 批量审核
  batchAudit(opt){
    return Axios({
      url: '/integdetail/batchaudit',
      data: opt
    })
  },

  // 个人积分排行
  personalRanking(opt) {
    return Axios({
      url: '/integdetail/personalranking',
      data: opt
    })
  },

  // 个人积分排行
  teamRanking(opt) {
    return Axios({
      url: '/integdetail/teamranking',
      data: opt
    })
  },

  // 排行查询
  ranking(opt) {
    return Axios({
      url: '/integdetail/ranking',
      data: opt
    })
  },
}

// 公告类
export const Notice = {
  // 添加公告
  add(opt) {
    return Axios({
      url: '/notice/add',
      data: opt
    })
  },

  // 删除公告
  delete(opt) {
    return Axios({
      url: '/notice/delete',
      data: opt
    })
  },

  // 公告列表
  list(opt) {
    return Axios({
      url: '/notice/list',
      data: opt
    })
  }
}

// 自定义名称
export const Custom = {
  // 获取自定义名称
  get() {
    return Axios({
      url: '/custom/get'
    })
  },

  // 设置自定义名称
  set(opt) {
    return Axios({
      url: '/custom/set',
      data: opt
    })
  }
}

// 系统类
export const System = {
  // 添加使用手册
  setHandbook(opt) {
    return Axios({
      url: '/system/sethandbook',
      data: opt
    })
  },

  // 获取使用手册
  getHandbook() {
    return Axios({
      url: '/system/gethandbook',
    })
  },

  // 添加使用手册
  setVideo(opt) {
    return Axios({
      url: '/system/setvideo',
      data: opt
    })
  },

  // 获取使用手册
  getVideo() {
    return Axios({
      url: '/system/getvideo',
    })
  },
}
