import React, { Component } from 'react';

import { Form, Input, Button, message, Table } from 'antd'

import { System } from '../../../utils/request'

class Video extends Component {
  state = {
    columns: [
      { title: '标题名称', dataIndex: 'title' },
      {
        title: '查看', dataIndex: 'url',
        render: (url, record) => (
          <a href={url} target='_blank'>查看</a>
        )
      }
    ],
    dataSource: [],
    identity: null
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const that = this
    const { form } = this.props
    form.validateFields((err, values) => {
      if (err) {
        console.log(err);
        return message.error('程序错误!', 3)
      }

      System.setVideo(values)
        .then(res => {
          const { success, msg } = res.data
          if (success) {
            message.success('添加成功!', 3)
            form.resetFields()
            that.getVideo()
          } else {
            message.error(msg, 3)
          }
        })
        .catch(err => {
          console.log(err);
          message.error('程序错误!', 3)
        })
    })
  }

  getVideo = () => {
    System.getVideo()
      .then(res => {
        const { success, msg, resData } = res.data
        if (success) {
          const dataSource = resData.map(item => ({ ...item, key: item._id }))
          this.setState({ dataSource })
        } else {
          message.error(msg, 3)
        }
      })
      .catch(err => {
        console.log(err);
        message.error('程序错误!', 3)
      })
  }

  componentDidMount() {
    const userInfo = window.localStorage.getItem('userInfo') || '{}'
    const { identity } = JSON.parse(userInfo)
    this.setState({ identity })
    this.getVideo()
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { dataSource, columns, identity } = this.state
    return (
      <div id='Video'>
        {identity === 0 ? (<Form onSubmit={this.handleSubmit} layout='inline' style={{ paddingBottom: '20px' }}>
          <Form.Item>
            {getFieldDecorator('title', {
              rules: [{ required: true, message: '请输入标题名称!' }],
            })(
              <Input placeholder='标题名称' />
            )}
          </Form.Item>

          <Form.Item>
            {getFieldDecorator('url', {
              rules: [{ required: true, message: '请输入文件地址!' }],
            })(
              <Input placeholder='文件地址' />
            )}
          </Form.Item>

          <Form.Item>
            <Button type='primary' htmlType='submit'>添加</Button>
          </Form.Item>
        </Form>) : null}

        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
        >
        </Table>
      </div>
    );
  }
}

Video = Form.create({})(Video);


export default Video;