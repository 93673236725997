import React, { Component } from 'react';

// 导入第三方组件
import { Form, Input, Button, Upload, Icon, message, Table, Popconfirm } from 'antd'

// 导入工具类
import { Host, Api, Notice } from '../../../utils/request'

class NoticeManage extends Component {
  state = {
    rows: 0, // 用户列表数据总条数
    pageSize: 10, // 每页显示条数
    pages: 1, // 当前页数
    imgUrl: '',
    loading: null,
    columns: [
      { title: '标题', dataIndex: 'title' },
      { title: '作者', dataIndex: 'author' },
      { title: '日期', dataIndex: 'publishTime' },
      { title: '内容', dataIndex: 'content' },
      {
        title: '缩略图', dataIndex: 'img',
        render: (text, record) => (
          text ? <img src={`${Host}${text}`} style={{ width: '90px' }} alt='缩略图' /> : null
        )
      },
      {
        title: '管理', dataIndex: 'manage',
        render: (text, record) => (
          <div className='userManage clearfix'>
            <Popconfirm title="确定删除?" okText="是" cancelText="否" onConfirm={() => this.deleteNotice(record.key)}>
              <span style={{ color: '#1890ff', cursor: 'pointer' }}>删除</span>
            </Popconfirm>
          </div>
        )
      },
    ],
    dataSource: []
  }

  // 删除公告
  deleteNotice = (id) => {
    // 发送数据
    Notice.delete({ id })
      .then(res => {
        // 请求成功
        const { success, msg } = res.data
        if (success) {
          // 删除成功
          message.success('删除成功!', 3)
          this.setState({ pages: 1 })
          this.getNoticeList(1)
        } else {
          // 删除失败
          message.error(msg, 3)
        }
      })
      .catch(err => {
        // 请求失败
        console.log(err);
        message.error('服务器错误!', 3)
      })
  }

  beforeUpload = (file) => {
    console.log(file);

    // 如果上传图片大于1MB 返回
    if (file.size > 1024 * 1024) {
      message.warn('缩略图最大为1M!建议尺寸为360*240像素!', 3)
      return false
    } else {
      this.setState({ fileList: [file] })
    }
  }

  // 获取公告列表
  getNoticeList = (pages = 1) => {
    // 请求数据
    Notice.list({ pages })
      .then(res => {
        // 请求成功
        const { success, msg, resData, rows } = res.data
        if (success) {
          // 查询成功
          const dataSource = resData.map(item => {
            return {
              title: item.title,
              author: item.author,
              content: item.content,
              publishTime: new Date(item.publishTime).toLocaleDateString(),
              img: item.img || '',
              manage: item.department || '',
              key: item._id
            }
          })
          this.setState({ dataSource, rows: rows })
        } else {
          // 查询失败
          message.error(msg, 3)
        }
      })
      .catch(err => {
        // 请求失败
        console.log(err);
        message.error('程序错误!', 3)
      })
  }

  // 提交表单
  handleSubmit = (e) => {
    e.preventDefault()
    const that = this
    const form = this.props.form
    form.validateFields((err, values) => {
      if (err) {
        console.log(err);
        return message.error('程序错误!', 3)
      }

      // 缩略图
      values.img = that.state.imgUrl

      // 发送数据
      Notice.add(values)
        .then(res => {
          // 请求成功
          const { success, msg } = res.data
          if (success) {
            // 添加成功
            message.success('添加成功!', 3)
            let upload = JSON.parse(window.localStorage.getItem('upload')) || {}
            upload.notice && delete upload.notice
            window.localStorage.setItem('upload', JSON.stringify(upload))
            form.resetFields()
            that.setState({ pages: 1, imgUrl: '' })
            that.getNoticeList(1)
          } else {
            // 添加失败
            message.error(msg, 3)
          }
        })
        .catch(err => {
          // 请求失败
          console.log(err);
          message.error('程序错误!', 3)
        })
    })
  }

  // 处理文件状态变化
  handleUploadChange = (info) => {
    const status = info.file.status

    // 判断状态已完成 并且上传成功
    if (status === 'done' && info.file.response.success) {

      // 获取upload
      let upload = JSON.parse(window.localStorage.getItem('upload')) || {}

      // 把文件路径存到 upload
      upload.notice = info.file.response.resData

      // 保存到localStorage
      window.localStorage.setItem('upload', JSON.stringify(upload))

      // 后台图片路径 存到state 提交表单时发送到后台
      this.setState({ imgUrl: info.file.response.resData, loading: false })
    }

    if (status === 'uploading') {
      this.setState({ loading: true });
    }

    if (status === 'error') {
      message.error('缩略图上传失败!', 3)
    }
  }

  // 换页
  changePage = (pages) => {
    this.setState({ pages }, () => {
      this.getNoticeList(pages)
    })
  }

  componentDidMount() {
    this.getNoticeList(1)
  }

  render() {
    const { Item } = Form
    const { getFieldDecorator } = this.props.form;
    const { imgUrl , dataSource, columns, pageSize, pages, rows} = this.state
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">上传缩略图</div>
      </div>
    );
    return (
      <div id='NoticeManage'>
        <Form className='NoticeManageForm' onSubmit={this.handleSubmit}>

          {/* 标题 */}
          <Item className='item' label='标题'>
            {getFieldDecorator('title', {
              rules: [{ required: true, message: '请输入公告标题' }]
            })(
              <Input placeholder="标题" style={{ width: '240px' }} />
            )}
          </Item>

          {/* 作者 */}
          <Item className='item' label='作者'>
            {getFieldDecorator('author', {
              rules: [{ required: true, message: '请输入公告作者' }]
            })(
              <Input placeholder="作者" style={{ width: '240px' }} />
            )}
          </Item>

          <Upload
            action={Api + '/notice/upload'}
            accept=".jpg,.png"
            listType="picture-card"
            beforeUpload={this.beforeUpload}
            onChange={this.handleUploadChange}
            showUploadList={false}
            withCredentials={true}
          >
            {imgUrl ? (<img src={Host + imgUrl} width='100' alt="" />) : uploadButton}
          </Upload>

          {/* 内容 */}
          <Item className='item' label='公告内容'>
            {getFieldDecorator('content', {
              rules: [{ required: true, message: '请输入公告内容' }]
            })(
              <Input.TextArea placeholder="公告内容" style={{ width: '60%' }} />
            )}
          </Item>

          {/* 提交按钮 */}
          <Form.Item className='item'>
            <Button type="primary" htmlType="submit">添加</Button>
          </Form.Item>
        </Form>

        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={{
            pageSize: pageSize,
            current: pages,
            onChange: this.changePage,
            total: rows
          }}
        ></Table>
      </div>
    );
  }
}

NoticeManage = Form.create({})(NoticeManage);

export default NoticeManage;