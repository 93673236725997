import React, { Component } from 'react';
import './index.scss'
class Ft extends Component {
  render() {
    return (
      <div id={'Ft'}>
        <p>2016 © 海贝教育连锁 版权所有.</p>
      </div>
    );
  }
}

export default Ft;