import React, { Component } from 'react';
import { Button, Table, message } from 'antd'
import { IntegDetail } from '../../../utils/request';
import HandleDate from '../../../utils/HandleDate'
const { areaToDate } = HandleDate

class PersonalRanking extends Component {
  state = {
    ranking: [], // 排名数据
    area: 'day', // 时间范围
    custom: {},
    all: 0,
    rankingDetails: {}
  }

  query = (opt) => {
    
    message.loading('数据加载中...', 0)
    IntegDetail.personalRanking(opt)
      .then(res => {
        const { success, msg, resData } = res.data
        if (success) {
          let ranking = Object.entries(resData).map(item => {
            return {
              key: item[0],
              department: item[1].department,
              team: item[1].team,
              name: item[1].name,
              value: item[1].value,
              detail: item[1].detail
            }
          })

          ranking = ranking.sort((a, b) => b.value - a.value)

          const area = opt.area
          const times = areaToDate(area)
          ranking.forEach(item => {
            this.getRankingDetail({ apply: item.key, startTime: times[0], endTime: times[1], pages: 1, status: 2 })
          })

          this.setState({ ranking, area: opt.area }, () => {
            message.destroy()
          })
        } else {
          message.error(msg, 3)
        }
      })
      .catch(err => {
        console.log(err);
        message.destroy()
        message.error('程序错误!', 3)
      })
  }

  initColumns = () => {
    const { custom, all } = this.state
    const columns = [
      { title: `${custom.team || '团队'}名称`, dataIndex: 'team' },
      { title: `${custom.user || '教师'}名称`, dataIndex: 'name' },
      { title: `积分`, dataIndex: 'value' },
    ]
    if (all) {
      columns.unshift({ title: `${custom.department || '校区'}名称`, dataIndex: 'department' })
    }
    this.setState({ columns })
  }

  componentDidMount() {
    const custom = window.localStorage.getItem('custom') || '{}'
    const userInfo = window.localStorage.getItem('userInfo') || '{}'
    const all = JSON.parse(userInfo).all === undefined ? 0 : JSON.parse(userInfo).all
    this.setState({ custom: JSON.parse(custom), all }, this.initColumns)
  }

  getRankingDetail = (opt) => {

    IntegDetail.list(opt)
      .then(res => {
        const { success, msg, resData, rows } = res.data
        if (success) {
          const dataSource = resData.map(item => {
            return {
              key: item._id,
              firstType: item.firstType.name,
              secondType: item.secondType.name,
              singleValue: item.singleValue,
              count: item.count,
              totalValue: item.totalValue,
              name: item.apply.name,
              createdTime: new Date(item.createdTime).toLocaleString(),
              type: item.type ? '加分' : '申请',
              status: item.status === 2 ? '已审' : ''
            }
          })
          const rankingDetails = this.state.rankingDetails
          rankingDetails[opt.apply] = {
            dataSource,
            pages: opt.pages,
            rows
          }
          this.setState({ rankingDetails })
        }
      })
      .catch(err => {
        console.log(err);
        message.error('程序错误!', 3)
      })
  }

  detailPageChange = (apply, pages, pageSize) => {
    const area = this.state.area
    const times = areaToDate(area)
    this.getRankingDetail({ apply, pages, startTime: times[0], endTime: times[1], status: 2 })
  }

  render() {
    const { ranking, columns } = this.state
    const DetailTable = (record) => {
      const rankingDetail = this.state.rankingDetails[record.key] || { dataSource: [], pages: 1, rows: 0 }
      return (
        <Table
          columns={[
            { title: '顶级积分类型', dataIndex: 'firstType' },
            { title: '二级积分类型', dataIndex: 'secondType' },
            { title: '单项积分', dataIndex: 'singleValue' },
            { title: '申请数量', dataIndex: 'count' },
            { title: '总积分', dataIndex: 'totalValue' },
            { title: '姓名', dataIndex: 'name' },
            { title: '申请时间', dataIndex: 'createdTime' },
            { title: '积分类型', dataIndex: 'type' },
            { title: '审核状态', dataIndex: 'status' },
          ]}
          dataSource={rankingDetail.dataSource}
          pagination={{
            current: rankingDetail.pages,
            pageSize: 10,
            total: rankingDetail.rows,
            onChange: this.detailPageChange.bind(this, record.key)
          }}
        ></Table>
      )
    }

    return (
      <div id='PersonalRanking'>
        <Button type='primary' onClick={this.query.bind(this, { area: 'day' })} style={{ marginRight: '20px' }}>今日积分</Button>
        <Button type='primary' onClick={this.query.bind(this, { area: 'week' })} style={{ marginRight: '20px' }}>本周积分</Button>
        <Button type='primary' onClick={this.query.bind(this, { area: 'month' })} style={{ marginRight: '20px' }}>本月积分</Button>
        <Button type='primary' onClick={this.query.bind(this, { area: 'year' })} style={{ marginRight: '20px' }}>年度积分</Button>

        <Table
          dataSource={ranking}
          columns={columns}
          expandedRowRender={DetailTable}
          pagination={false}
          style={{ marginTop: '20px' }}
        />
      </div>
    );
  }
}

export default PersonalRanking;