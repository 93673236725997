import React, { Component } from 'react';

// 导入第三方组件
import { Table, Icon, message, Modal, Popconfirm, Button, Form, LocaleProvider, DatePicker, Select } from 'antd'
import OpinionModal from '../OpinionModal'

// 导入工具类
import { Host, IntegDetail, User } from '../../../utils/request'

// 配置日期控件语言
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');

class IntegAudit extends Component {
  state = {
    pages: 1,
    pageSize: 10,
    rows: 0,
    custom: {},
    dataSource: [],
    columns: [],
    showPreview: false,
    previewContent: '',
    selectedRowKeys: [],
    startTime: '',
    endTime: '',
    opinionModalShow: false,
    opt: {},
    users: [],
  }

  preview = (path) => {
    this.setState({
      showPreview: true,
      previewContent: /\.mp4$/.test(path) ?
        (<video controls width='800' height='600'>
          <source src={Host + path}
            type="video/mp4"></source>
        </video>) :
        (<img src={Host + path} alt='' height='600' />)
    })
  }

  closePreview = () => {
    this.setState({ showPreview: false })
  }

  initColumns = () => {
    const { custom } = this.state
    this.setState({
      columns: [
        { title: '顶级积分类型', dataIndex: 'firstType' },
        { title: '二级积分类型', dataIndex: 'secondType' },
        { title: '单项积分', dataIndex: 'singleValue' },
        { title: '总积分', dataIndex: 'totalValue' },
        { title: '申请数量', dataIndex: 'count' },
        { title: `申请人${custom.department || '校区'}`, dataIndex: 'department' },
        { title: `申请人${custom.team || '团队'}`, dataIndex: 'team' },
        { title: `申请人姓名`, dataIndex: 'apply' },
        { title: `申请时间`, dataIndex: 'createdTime' },
        { title: `备注`, dataIndex: 'remarks' },
        {
          title: '附件', dataIndex: 'attachment',
          render: (text, record) => {
            if (/\.mp4$/.test(text[0])) {
              return (
                <Icon type="video-camera" onClick={this.preview.bind(this, text[0])} title='点击查看视频' />
              )
            } else {
              return text.map(path => {
                return <img src={Host + path} key={path} width='50' onClick={this.preview.bind(this, path)} alt='' title='点击查看大图' />
              })
            }
          }
        },
        { title: `审核状态`, dataIndex: 'status' },
        {
          title: '审核', dataIndex: 'manage',
          render: (text, record) => (
            <div>
              <Popconfirm title="确定批准?" okText="是" cancelText="否" onConfirm={() => this.audit({ id: record.key, status: 2 })}>
                <span style={{ color: '#1890ff', cursor: 'pointer', display: 'inline-block', marginRight: '10px' }}>批准</span>
              </Popconfirm>
              <Popconfirm title="确定不批准?" okText="是" cancelText="否" onConfirm={() => this.showOpinionModal({ id: record.key, status: 3 }, 1)}>
                <span style={{ color: '#1890ff', cursor: 'pointer', display: 'inline-block', marginRight: '10px' }}>不批准</span>
              </Popconfirm>
              <Popconfirm title="确定作废?" okText="是" cancelText="否" onConfirm={() => this.showOpinionModal({ id: record.key, status: 0 }, 1)}>
                <span style={{ color: '#1890ff', cursor: 'pointer' }}>作废</span>
              </Popconfirm>
            </div>
          )
        },
      ],
    })
  }

  showOpinionModal = (opt, type) => {
    let audit = type === 1 ? this.audit : this.batchAudit
    this.setState({ opt, opinionModalShow: true, audit })
  }

  // 审核
  audit = (opt) => {
    console.log(opt)
    IntegDetail.audit(opt)
      .then(res => {
        const { success, msg } = res.data
        if (success) {
          message.success('审核成功!', 3)
          this.setState({ opinionModalShow: false })
          this.getIntegList()
        } else {
          message.error(msg, 3)
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ opinionModalShow: false })
        message.error('程序错误!', 3)
      })
  }

  batchAudit = (opt = {}) => {
    const { selectedRowKeys } = this.state
    const { status, opinion } = opt
    if (status && !selectedRowKeys.length) return message.warn('未选中积分!', 3)
    IntegDetail.batchAudit({ ids: status ? selectedRowKeys : [], status, opinion: opinion || '' })
      .then(res => {
        const { success, msg } = res.data
        if (success) {
          message.success('审核成功!', 3)
          this.setState({ opinionModalShow: false })
          this.getIntegList()
        } else {
          message.error(msg, 3)
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ opinionModalShow: false })
        message.error('程序错误!', 3)
      })
  }

  getIntegList = () => {
    const { pages, startTime, endTime, apply } = this.state

    IntegDetail.manage({ status: 1, pages, startTime, endTime, apply })
      .then(res => {
        const { success, msg, resData, rows } = res.data
        if (success) {
          if (!resData.length) message.warn('暂无数据', 3)
          const dataSource = resData.map(item => {
            return {
              firstType: item.firstType.name,
              secondType: item.secondType.name,
              singleValue: item.singleValue,
              count: item.count,
              totalValue: item.totalValue,
              department: item.department.name,
              team: item.team.name,
              apply: item.apply ? item.apply.name : '',
              createdTime: new Date(item.createdTime).toLocaleString(),
              remarks: item.remarks,
              attachment: item.attachment,
              status: item.status === 1 ? '待审' : '',
              key: item._id
            }
          })
          this.setState({ dataSource, rows })
        } else {
          message.error(msg, 3)
        }
      })
      .catch(err => {
        console.log(err);
        message.error('积分列表获取失败!', 3)
      })
  }

  changePage = (pages) => {
    this.setState({ pages }, () => {
      this.getIntegList()
    })
  }

  // 当日期控件的值改变时 保存到state
  onDateChange = (date, dateString) => {
    this.setState({ startTime: dateString[0], endTime: dateString[1], pages: 1, selectedRowKeys: [] }, () => {
      this.getIntegList()
    })
  }

  componentDidMount() {
    const custom = window.localStorage.getItem('custom') || '{}'
    this.setState({ custom: JSON.parse(custom) }, this.initColumns)
    this.getIntegList()
    this.getUserList()
  }

  hideOpinionModal = () => {
    this.setState({ opinionModalShow: false })
  }

  // 根据所选团队获取用户列表
  getUserList = () => {
    User.userList({ pages: 0, used: true })
      .then(res => {
        if (res.data.success) {
          const users = res.data.resData.map(user => {
            return { name: user.name, id: user._id }
          })
          this.setState({ users })
        } else { message.error(res.data.msg, 3) }
      })
      .catch(err => { console.log(err) })
  }

  userChange = (val) => {
    this.setState({ apply: val, pages: 1, selectedRowKeys: [] }, () => {
      this.getIntegList()
    })
  }

  render() {
    const { dataSource, columns, pageSize, pages, rows, showPreview, opt, opinionModalShow, audit, users } = this.state
    const { RangePicker } = DatePicker
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        this.setState({
          selectedRowKeys
        })
      },
      getCheckboxProps: record => ({
        disabled: record.name === 'Disabled User', // Column configuration not to be checked
        name: record.name,
      }),
    };
    const { getFieldDecorator } = this.props.form;
    return (
      <div id='IntegAudit'>
        <Form style={{ paddingBottom: '20px' }}>

          {/* 起止时间 */}
          <Form.Item label='选择日期'>
            <LocaleProvider locale={zh_CN}>
              <RangePicker onChange={this.onDateChange} placeholder={['起始时间', '截止时间']} />
            </LocaleProvider>
          </Form.Item>

          {/* 选择教师 */}
          <Form.Item label='选择用户'>
            {getFieldDecorator('auditType', {})(
              <Select style={{ width: '320px' }} onChange={this.userChange}>
                {
                  users.map(item => {
                    return <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                  })
                }
              </Select>
            )}
          </Form.Item>
        </Form>

        <div className='batch-audit' style={{ paddingBottom: '20px' }}>
          <Popconfirm title="是否确认批准全部待审积分?" okText="是" cancelText="否" onConfirm={() => this.batchAudit()}>
            <Button type='primary' style={{ marginRight: '20px' }}>批准全部待审积分</Button>
          </Popconfirm>
          <Button type='primary' style={{ marginRight: '20px' }} onClick={this.batchAudit.bind(this, { status: 2 })}>批准选中项</Button>
          <Button type='primary' onClick={this.showOpinionModal.bind(this, { status: 3 })}>不批准选中项</Button>
        </div>

        <Table
          dataSource={dataSource}
          columns={columns}
          rowSelection={rowSelection}
          pagination={{
            pageSize,
            current: pages,
            total: rows,
            onChange: this.changePage,
          }}
        />

        <Modal
          footer={null}
          width={1000}
          style={{ textAlign: 'center' }}
          onCancel={this.closePreview}
          visible={showPreview}
        >
          {this.state.previewContent}
        </Modal>

        <OpinionModal opt={opt} audit={audit} show={opinionModalShow} hideOpinionModal={this.hideOpinionModal} />
      </div>
    );
  }
}

IntegAudit = Form.create({})(IntegAudit);

export default IntegAudit;