// 首页
import Home from '../components/Home'

// 用户管理
import UserManage from '../components/User/Manage'

// 个人信息
import PersonalInfo from '../components/User/Personal'

// 修改子级用户信息
import UpdateChild from '../components/User/UpdateChild'

// 团队成员
import TeamMember from '../components/User/TeamMember'

// 积分类型管理
import IntegTypeManage from '../components/IntegType/Manage'

// 积分类型模板
import IntegTypeTemplate from '../components/IntegType/Template'

// 申请积分
import IntegApply from '../components/Integ/Apply'

// 我的积分
import MyInteg from '../components/Integ/MyInteg'

// 审核积分
import IntegAudit from '../components/Integ/Audit';

// 管理积分
import IntegManage from '../components/Integ/Manage'

// 自定义查询
import IntegQuery from '../components/Integ/Query'

// 自定义名称
import Custom from '../components/Custom'

// 公告管理
import NoticeManage from '../components/Notice/Manage'

// 校区管理
import DepartmentManage from '../components/Department/Manage'

// 修改校区信息
import DepartmentUpdate from '../components/Department/Update'

// 团队管理
import TeamManage from '../components/Team/Manage'

// 更新团队信息
import TeamUpdate from '../components/Team/Update'

// 使用手册
import Handbook from '../components/System/Handbook'

// 视频教程
import Video from '../components/System/Video';
import IntegDetail from '../components/Integ/Detail';
import AddInteg from '../components/Integ/addInteg';
import IntegDelete from '../components/Integ/Delete';
import PersonalRanking from '../components/Integ/PersonalRanking';
import TeamRanking from '../components/Integ/TeamRanking';
import Ranking from '../components/Integ/Ranking';
import IntegTypeUpdate from '../components/IntegType/Update';

export default [
  { // 首页
    path: '/',
    component: Home,
    exact: true
  },
  { // 用户管理
    path: '/user/manage',
    component: UserManage,
  },
  { // 个人信息
    path: '/user/personal/:name',
    component: PersonalInfo,
  },
  { // 修改子级用户信息
    path: '/user/updatechild/:id',
    component: UpdateChild,
  },
  { // 团队成员
    path: '/user/teammember',
    component: TeamMember,
  },
  { // 自定义名称
    path: '/custom',
    component: Custom,
  },
  { // 积分类型管理
    path: '/integtype/manage',
    component: IntegTypeManage,
  },
  { // 积分类型更新
    path: '/integtype/update/:id',
    component: IntegTypeUpdate,
  },
  { // 积分类型模板
    path: '/integtype/template',
    component: IntegTypeTemplate,
  },
  { // 申请积分
    path: '/integ/apply',
    component: IntegApply,
  },
  { // 我的积分
    path: '/integ/myinteg',
    component: MyInteg,
  },
  { // 积分查询
    path: '/integ/query',
    component: IntegQuery,
  },
  { // 积分审核
    path: '/integ/audit',
    component: IntegAudit,
  },
  { // 积分管理
    path: '/integ/manage',
    component: IntegManage,
  },
  { // 积分信息
    path: '/integ/detail/:id',
    component: IntegDetail,
  },
  { // 积分加分
    path: '/integ/add',
    component: AddInteg,
  },
  { // 积分清空
    path: '/integ/delete',
    component: IntegDelete,
  },
  { // 个人积分排行
    path: '/integ/personalranking',
    component: PersonalRanking,
  },
  { // 团队积分排行
    path: '/integ/teamranking',
    component: TeamRanking,
  },
  { // 排行查询
    path: '/integ/ranking',
    component: Ranking,
  },
  { // 公告管理
    path: '/notice/manage',
    component: NoticeManage,
  },
  { // 校区管理
    path: '/department/manage',
    component: DepartmentManage,
  },
  { // 校区信息修改
    path: '/department/update/:id',
    component: DepartmentUpdate,
  },
  { // 团队管理
    path: '/team/manage',
    component: TeamManage,
  },
  { // 团队管理
    path: '/team/update/:id',
    component: TeamUpdate,
  },
  { // 使用手册
    path: '/system/handbook',
    component: Handbook,
  },
  { // 教程视频
    path: '/system/video',
    component: Video,
  },
]