import React, { Component } from 'react';
import { Button, Table, message, Form, Select, Cascader, DatePicker } from 'antd'
import { IntegDetail, IntegType } from '../../../utils/request';

// 配置日期控件语言
import { LocaleProvider } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';

import HandleDate from '../../../utils/HandleDate'
const { areaToDate } = HandleDate

moment.locale('zh-cn');

class Ranking extends Component {
  state = {
    ranking: [], // 排名数据
    custom: {},
    all: 0,
    types: [],
    queryTypes: [],
    startTime: '',
    endTime: '',
    rankingDetails: {}
  }

  query = (e) => {
    e.preventDefault()
    const that = this
    const { form } = this.props
    form.validateFields((err, values) => {
      if (!err) {
        this.initColumns(values.level)
        const { startTime, endTime } = that.state
        message.loading('数据加载中...', 0)

        IntegDetail.ranking({ ...values, startTime, endTime })
          .then(res => {
            const { success, msg, resData } = res.data
            if (success) {

              let ranking = Object.entries(resData).map(item => {
                const i = {
                  key: item[0],
                  department: item[1].department,
                  team: item[1].team,
                  name: item[1].name || '',
                  value: item[1].value,
                  detail: item[1].detail
                }

                if (values.level === 2) {
                  i.average = (item[1].value / (item[1].users.length || 1)).toFixed(2)
                }

                if (values.level === 1) {
                  i.average = (item[1].value / (item[1].teams.length || 1)).toFixed(2)
                }
                return i
              })

              if (values.level === 3) {
                ranking = ranking.sort((a, b) => b.value - a.value)
              } else {
                ranking = ranking.sort((a, b) => b.average - a.average)
              }


              that.setState({ ranking, level: values.level, queryTypes: values.types }, () => {
                message.destroy()

                ranking.forEach(item => {
                  that.getRankingDetail({ id: item.key, pages: 1 })
                })
              })
            } else {
              message.error(msg, 3)
            }
          })
          .catch(err => {
            console.log(err);
            message.destroy()
            message.error('程序错误!', 3)
          })
      }
    })
  }

  initColumns = (level) => {
    const { custom } = this.state
    const columns = [
      { title: `总分`, dataIndex: 'value' },
    ]

    if (level === 1) {
      columns.unshift({ title: `${custom.department || '校区'}名称`, dataIndex: 'department' })
      columns.push({ title: `平均分`, dataIndex: 'average' })
    } else if (level === 2) {
      columns.unshift({ title: `${custom.team || '团队'}名称`, dataIndex: 'team' })
      columns.unshift({ title: `${custom.department || '校区'}名称`, dataIndex: 'department' })
      columns.push({ title: `平均分`, dataIndex: 'average' })
    } else if (level === 3) {
      columns.unshift({ title: `${custom.user || '教师'}名称`, dataIndex: 'name' })
      columns.unshift({ title: `${custom.team || '团队'}名称`, dataIndex: 'team' })
      columns.unshift({ title: `${custom.department || '校区'}名称`, dataIndex: 'department' })
    }
    this.setState({ columns })
  }

  // 当日期控件的值改变时 保存到state
  onDateChange = (date, dateString) => {
    this.setState({ startTime: dateString[0], endTime: dateString[1] })
  }

  // 获取积分类型列表
  getTypeList = () => {
    IntegType.list({ pages: 0 })
      .then(res => {
        const { resData, success } = res.data
        if (success) {
          const types = []
          resData.forEach(item1 => {
            if (!item1.level) { // 顶级类型
              types.push({ value: item1._id, label: item1.name, children: [] })
            } else { // 二级类型
              types.forEach(item2 => {
                if (item2.label === item1.fid.name) {
                  item2.children.push({ value: item1._id, label: item1.name, })
                }
              })
            }
          })
          this.setState({ types })
        }
      })
      .catch(err => { console.log(err); })
  }

  componentDidMount() {
    const custom = window.localStorage.getItem('custom') || '{}'
    const userInfo = window.localStorage.getItem('userInfo') || '{}'
    const all = JSON.parse(userInfo).all === undefined ? 0 : JSON.parse(userInfo).all
    const identity = JSON.parse(userInfo).identity === undefined ? 0 : JSON.parse(userInfo).identity
    this.setState({ custom: JSON.parse(custom), all, identity, level: identity === 1 ? 1 : 2 })
    this.getTypeList()
  }

  getRankingDetail = (opt) => {
    const { level, startTime, endTime, queryTypes } = this.state
    const _opt = {
      pages: opt.pages,
      status: 2,
      startTime,
      endTime,
      types: queryTypes,
    }
    if (level === 1) _opt.department = opt.id
    if (level === 2) _opt.team = opt.id
    if (level === 3) _opt.apply = opt.id
    IntegDetail.list(_opt)
      .then(res => {
        const { success, msg, resData, rows } = res.data
        if (success) {
          const dataSource = resData.map(item => {
            return {
              key: item._id,
              firstType: item.firstType ? item.firstType.name : '',
              secondType: item.secondType ? item.secondType.name : '',
              singleValue: item.singleValue,
              count: item.count,
              totalValue: item.totalValue,
              name: item.apply ? item.apply.name : '',
              createdTime: new Date(item.createdTime).toLocaleString(),
              type: item.type ? '加分' : '申请',
              status: item.status === 2 ? '已审' : ''
            }
          })
          const rankingDetails = this.state.rankingDetails
          rankingDetails[opt.id] = {
            dataSource,
            pages: opt.pages,
            rows
          }
          this.setState({ rankingDetails })
        }
      })
      .catch(err => {
        console.log(err);
        message.error('程序错误!', 3)
      })
  }

  detailPageChange = (id, pages, pageSize) => {
    this.getRankingDetail({ id, pages })
  }

  render() {
    const { ranking, columns, types, custom, identity, all } = this.state
    const { RangePicker } = DatePicker
    const { Option } = Select
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 2 },
      wrapperCol: { span: 4 },
    }

    const DetailTable = (record) => {
      const rankingDetail = this.state.rankingDetails[record.key] || { dataSource: [], pages: 1, rows: 0 }
      return (
        <Table
          columns={[
            { title: '顶级积分类型', dataIndex: 'firstType' },
            { title: '二级积分类型', dataIndex: 'secondType' },
            { title: '单项积分', dataIndex: 'singleValue' },
            { title: '申请数量', dataIndex: 'count' },
            { title: '总积分', dataIndex: 'totalValue' },
            { title: '姓名', dataIndex: 'name' },
            { title: '申请时间', dataIndex: 'createdTime' },
            { title: '积分类型', dataIndex: 'type' },
            { title: '审核状态', dataIndex: 'status' },
          ]}
          dataSource={rankingDetail.dataSource}
          pagination={{
            current: rankingDetail.pages,
            pageSize: 10,
            total: rankingDetail.rows,
            onChange: this.detailPageChange.bind(this, record.key)
          }}
        ></Table>
      )
    }
    return (
      <div id='Ranking'>
        <Form onSubmit={this.query} layout='horizontal'>
          {/* 起止时间 */}
          <Form.Item label='起止时间' {...formItemLayout}>
            <LocaleProvider locale={zh_CN}>
              <RangePicker onChange={this.onDateChange} placeholder={['起始时间', '截止时间']} />
            </LocaleProvider>
          </Form.Item>

          {/* 选择积分类型 */}
          {identity !== 1 ? (<Form.Item label={`积分类型`} {...formItemLayout}>
            {getFieldDecorator('types', {})(
              <Cascader options={types} onChange={this.changeType} changeOnSelect placeholder="积分类型" />
            )}
          </Form.Item>) : null}

          {/* 选择积分类型 */}
          <Form.Item label={`查询范围`} {...formItemLayout}>
            {getFieldDecorator('level', {
              initialValue: identity === 1 ? 1 : 2
            })(
              <Select>
                {identity === 1 || all ? <Option value={1}>{custom.department || '校区'}</Option> : null}
                <Option value={2}>{custom.team || '团队'}</Option>
                <Option value={3}>个人</Option>
              </Select>
            )}
          </Form.Item>

          {/* 提交按钮 */}
          <Form.Item style={{ display: 'block' }}>
            <Button type="primary" htmlType="submit">提交查询</Button>
          </Form.Item>
        </Form>

        <Table
          dataSource={ranking}
          columns={columns}
          pagination={false}
          style={{ marginTop: '20px' }}
          expandedRowRender={DetailTable}
        />
      </div>
    );
  }
}

Ranking = Form.create({})(Ranking);

export default Ranking;