const formatTime = (date, type) => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hour = date.getHours()
  const minute = date.getMinutes()
  const second = date.getSeconds()

  if (type === 'time') {
    return [year, month, day].map(formatNumber).join('-') + ' ' + [hour, minute, second].map(formatNumber).join(':')
  } else {
    return [year, month, day].map(formatNumber).join('-')
  }
}

const formatNumber = n => {
  n = n.toString()
  return n[1] ? n : '0' + n
}

const areaToDate = (area) => {
  let date = [];
  const _today = new Date()
  const _year = _today.getFullYear()
  const _month = _today.getMonth() + 1
  const _day = _today.getDate()
  const _week = _today.getDay() || 7

  switch (area) {
    case 'year':
      date[0] = new Date(_year + '-1-1')
      date[1] = new Date(_year + 1 + '-1-1')
      break

    case 'month':
      const nextMonth = _month + 1 > 12 ? 1 : _month + 1
      date[0] = new Date(_year + '-' + _month + '-1')
      date[1] = new Date(nextMonth === 1 ? _year + 1 : _year + '-' + nextMonth + '-1')
      break

    case 'week':
      date[0] = new Date(_today - (_week - 1) * 24 * 60 * 60 * 1000)
      date[1] = new Date(date[0].getTime() + 7 * 24 * 60 * 60 * 1000)
      break

    case 'day':
    default:
      date[0] = _today
      date[1] = new Date(Date.now() + 24 * 60 * 60 * 1000)
      break
  }

  return [formatTime(date[0]), formatTime(date[1])]
}

export default {
  formatTime,
  areaToDate
} 