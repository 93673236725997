import React, { Component } from 'react';

// 导入第三方组件
import { message, Form, Select, Button, Input } from 'antd'

// 导入工具类
import { IntegDetail as Integ } from '../../../utils/request'

class IntegDetail extends Component {
  state = {
    id: this.props.match.params.id, // 积分id
    info: {}, // 积分信息
  }

  // 提交查询
  handleSubmit = (e) => {
    e.preventDefault()
    const { form } = this.props
    form.validateFields((err, values) => {
      if (err) {
        console.log(err);
        return message.error('程序错误!', 3)
      }

      Integ.update({ id: this.state.id, ...values })
        .then(res => {
          const { success, msg } = res.data
          if (success) {
            message.success('修改成功!', 3)
          } else {
            message.error(msg, 3)
          }
        })
        .catch(err => {
          console.log(err);
          message.error('程序错误!', 3)
        })
    })
  }

  componentDidMount() {
    // 组件加载完成后 请求积分信息
    Integ.info({ id: this.state.id })
      .then(res => {
        const { success, msg, resData } = res.data
        if (success) {
          this.setState({ info: resData })
        } else {
          message.error(msg, 3)
        }
      })
      .catch(err => {
        console.log(err);
        message.error('程序错误!', 3)
      })
  }

  render() {
    const { info } = this.state
    const { getFieldDecorator } = this.props.form;
    return (
      <div id='IntegDetail'>
        <Form onSubmit={this.handleSubmit} layout='inline'>

          {/* 选择教师 */}
          <Form.Item label='申请人'>
            <Input disabled value={info.apply ? info.apply.name : ''} />
          </Form.Item>

          {/* 积分类型 */}
          <Form.Item label={`积分类型`}>
            <Input disabled value={info.secondType ? info.secondType.name : ''} />
          </Form.Item>

          {/* 单项积分 */}
          <Form.Item label={`单项积分`}>
            <Input disabled value={info.singleValue} />
          </Form.Item>

          {/* 申请数量 */}
          <Form.Item label={`申请数量`}>
            <Input disabled value={info.count} />
          </Form.Item>

          {/* 总积分 */}
          <Form.Item label={`总积分`}>
            <Input disabled value={info.totalValue} />
          </Form.Item>

          {/* 申请时间 */}
          <Form.Item label={`申请时间`}>
            <Input disabled value={new Date(info.createdTime).toLocaleString()} />
          </Form.Item>

          {/* 审核状态 */}
          <Form.Item label={`审核状态`}>
            {getFieldDecorator('status', {
              initialValue: info.status
            })(
              <Select>
                <Select.Option value={0}>作废</Select.Option>
                <Select.Option value={1}>待审</Select.Option>
                <Select.Option value={2}>已审</Select.Option>
                <Select.Option value={3}>不批准</Select.Option>
              </Select>
            )}
          </Form.Item>

          {/* 备注信息 */}
          <Form.Item label={`备注信息`}>
            {getFieldDecorator('remarks', {
              initialValue: info.remarks || ''
            })(
              <Input placeholder='备注信息' />
            )}
          </Form.Item>

          {/* 提交按钮 */}
          <Form.Item>
            <Button type="primary" htmlType="submit">修改</Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

IntegDetail = Form.create({})(IntegDetail);


export default IntegDetail;