import React, { Component } from 'react';

// 导入第三方组件
import { Form, Input, Button, message, Table, Popconfirm, Select } from 'antd'
import { Link } from 'react-router-dom'

// 导入工具类
import { Department } from '../../../utils/request'

class DepartmentManage extends Component {
  state = {
    custom: {},
    rows: 0,
    pages: 1,
    pageSize: 10,
    dataSource: [],
    columns: []
  }

  // 添加部门
  addDepartment = (e) => {
    e.preventDefault();
    const that = this
    const form = this.props.form
    form.validateFields((err, values) => {
      if (err) {
        console.log(err);
        return message.error('程序错误!', 3)
      }

      // 判断校区名称为空 清空表单 并返回
      if (!values.name.split(' ').join('')) {
        form.resetFields()
        return message.warn('无效字符!', 3)
      }

      // 发送数据
      Department.add(values)
        .then(res => {
          // 请求成功
          const { success, msg } = res.data
          if (success) {
            // 保存成功
            message.success('添加成功!', 3);
            that.getDepartmentList(1)
          } else {
            // 保存失败
            message.error(msg, 3)
          }
        })
        .catch(err => {
          // 请求错误
          console.log(err);
          message.error('程序错误!', 3);
        });
    });
  }

  getDepartmentList = (pages = 1) => {
    Department.list({ pages })
      .then(res => {
        const dataSource = []
        const { resData, rows } = res.data
        resData.forEach(item => {
          const i = {
            name: item.name,
            all: item.all ? '全部' : `本${this.state.custom.department || '校区'}`,
            key: item._id,
          }
          dataSource.push(i)
        })
        this.setState({ dataSource, rows: rows })
      })
      .catch(err => {
        console.log(err);
        message.error('部门列表获取错误!', 2)
      })
  }

  // 删除校区
  deleteDepartment = (id) => {
    // 发送数据
    Department.delete({ id })
      .then(res => {
        // 请求成功
        const { success, msg } = res.data
        if (success) {
          // 删除成功
          message.success('删除成功!', 3)
          this.setState({ pages: 1 })
          this.getDepartmentList(1)
        } else {
          // 删除失败
          message.error(msg, 3)
        }
      })
      .catch(err => {
        // 请求错误
        console.log(err);
        message.error('程序错误!', 3)
      })
  }

  // 初始化表头
  initColumns = () => {
    const { custom } = this.state
    this.setState({
      columns: [
        { title: `${custom.department || '校区'}名称`, dataIndex: 'name', },
        { title: `${custom.user || '教师'}权限`, dataIndex: 'all', },
        {
          title: '管理', dataIndex: 'manage',
          render: (text, record) => (
            <div className='departmentManage clearfix'>
              <Link to={`/department/update/${record.key}`} style={{ display: 'inline-block', marginRight: '10px' }}>修改</Link>
              <Popconfirm title="确定删除?" okText="是" cancelText="否" onConfirm={() => this.deleteDepartment(record.key)}>
                <span style={{ color: '#1890ff', cursor: 'pointer' }}>删除</span>
              </Popconfirm>
            </div>
          ),
        }
      ]
    }, this.getDepartmentList(1))
  }

  // 分页切换
  changePage = (pages) => {
    this.setState({ pages }, this.getDepartmentList(pages))
  }

  componentDidMount() {
    const custom = window.localStorage.getItem('custom') || '{}'
    this.setState({ custom: JSON.parse(custom) }, this.initColumns())
  }

  render() {
    const { dataSource, columns, pages, pageSize, rows } = this.state;
    const { getFieldDecorator } = this.props.form;
    const { Option } = Select
    return (
      <div id="DepartmentManage">
        <Form onSubmit={this.addDepartment} layout='inline' style={{ paddingBottom: '20px' }}>

          {/* 校区名称 */}
          <Form.Item>
            {getFieldDecorator('name', {
              rules: [{ required: true, message: '请输入校区名称!' }],
            })(
              <Input placeholder="校区名称" />
            )}
          </Form.Item>

          {/* 教师权限 */}
          <Form.Item>
            {getFieldDecorator('all', {
              initialValue: 0 // 默认本校区
            })(
              <Select>
                <Option value={0}>本{this.state.custom.department || '校区'}</Option>
                <Option value={1}>全部</Option>
              </Select>
            )}
          </Form.Item>

          {/* 提交按钮 */}
          <Form.Item>
            <Button type="primary" htmlType="submit">添加</Button>
          </Form.Item>
        </Form>

        <Table
          className='departmentList'
          dataSource={dataSource}
          columns={columns}
          pagination={{
            current: pages,
            pageSize,
            total: rows,
            onChange: this.changePage
          }}
        />
      </div>

    );


  }
}

DepartmentManage = Form.create({})(DepartmentManage);


export default DepartmentManage;