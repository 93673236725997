import React, { Component } from 'react';

import { Form, Input, Button, Modal } from 'antd'

class OpinionModal extends Component {
  audit = (e) => {
    e.preventDefault()
    const { opt, audit, form } = this.props
    form.validateFields((err, values) => {
      if (!err) {
        const { opinion } = values
        audit({ ...opt, opinion })
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { show } = this.props
    return (
      <Modal footer={null} visible={show} title='审核意见' onCancel={this.props.hideOpinionModal}>
        <Form onSubmit={this.audit}>

          {/* 审核意见 */}
          <Form.Item>
            {getFieldDecorator('opinion', {
              rules: [{ required: true, message: '请输入审核意见!' }],
            })(
              <Input placeholder='请输入审核意见' />
            )}
          </Form.Item>

          {/* 提交按钮 */}
          <Form.Item style={{ display: 'block' }}>
            <Button type="primary" htmlType="submit">提交</Button>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

OpinionModal = Form.create({})(OpinionModal);

export default OpinionModal;