import React, { Component } from 'react';

// 导入第三方组件
import { Form, message, Select, DatePicker, Icon, Button, Cascader, Table, Modal } from 'antd'

// 导入工具类
import { Host, IntegType, Team, User, IntegDetail, Department } from '../../../utils/request'

// 配置日期控件语言
import { LocaleProvider } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');

class IntegQuery extends Component {
	state = {
		teamList: [], // 团队列表
		custom: {}, // 自定义名称
		userList: [], // 教师列表
		types: [], // 积分类型联动列表
		time: [], // 起止时间
		showPreview: false,
		dataSource: [], // 表格数据
		rows: 0,// 数据总条数
		pageSize: 10, // 每页条数
		pages: 1, // 当前页数
		columns: [], // 表头
		opt: {},// 查询参数对象
		showSumBox: false,
		sum: 0,
		departmentList: []
	}

	// 提交表单
	handleSubmit = (e) => {
		e.preventDefault()
		const { form } = this.props
		const that = this
		form.validateFields((err, values) => {
			if (!err) {
				// 日期区间
				const { startTime, endTime } = this.state.opt
				values.startTime = startTime
				values.endTime = endTime

				that.getIntegList({ ...values, pages: this.state.pages })
			}
		})
	}

	preview = (path) => {
		this.setState({
			showPreview: true,
			previewContent: /\.mp4$/.test(path) ?
				(<video controls width='800' height='600'>
					<source src={Host + path}
						type="video/mp4"></source>
				</video>) :
				(<img src={Host + path} alt='' height='600' />)
		})
	}

	closePreview = () => {
		this.setState({ showPreview: false })
	}

	// 当日期控件的值改变时 保存到state
	onDateChange = (date, dateString) => {
		const { opt } = this.state
		opt.startTime = dateString[0]
		opt.endTime = dateString[1]
		this.setState({ opt })
	}

	// 获取积分类型列表
	getTypeList = () => {
		IntegType.list({ pages: 0 })
			.then(res => {
				const { resData, success } = res.data
				if (success) {
					const types = []
					resData.forEach(item1 => {
						if (!item1.level) { // 顶级类型
							types.push({ value: item1._id, label: item1.name, children: [] })
						} else { // 二级类型
							types.forEach(item2 => {
								if (item2.label === item1.fid.name) {
									item2.children.push({ value: item1._id, label: item1.name, })
								}
							})
						}
					})
					this.setState({ types })
				}
			})
			.catch(err => { console.log(err); })
	}

	// 获取所有校区
	getDepartmentList = () => {
		Department.list({ pages: 0 })
			.then(res => {
				if (res.data.success) {
					const departmentList = res.data.resData.map(item => {
						return { id: item._id, name: item.name }
					})
					this.setState({ departmentList })
				} else {
					message.error(res.data.msg, 3)
				}
			})
			.catch(err => { console.log(err); })
	}

	// 选择团队时 获取该团队的所有用户
	handleDepartmentChange = (department) => {
		this.props.form.resetFields(['apply', 'team'])
		this.getTeamList(department)
	}

	// 获取所有团队
	getTeamList = (department) => {
		Team.list({ pages: 0, department })
			.then(res => {
				if (res.data.success) {
					const teamList = res.data.resData.map(item => {
						return { id: item._id, name: item.name }
					})
					this.setState({ teamList })
				} else {
					message.error(res.data.msg, 3)
				}
			})
			.catch(err => { console.log(err); })
	}

	// 选择团队时 获取该团队的所有用户
	handleTeamChange = (team) => {
		this.props.form.resetFields(['apply'])
		this.getUserList({ team, pages: 0, used: true })
	}

	// 根据所选团队获取用户列表
	getUserList = (opt) => {
		User.userList(opt)
			.then(res => {
				if (res.data.success) {
					const userList = res.data.resData.map(user => {
						return { name: user.name, id: user._id }
					})
					this.setState({ userList })
				} else { message.error(res.data.msg, 3) }
			})
			.catch(err => { console.log(err) })
	}

	initColumns = () => {
		const { custom } = this.state
		this.setState({
			columns: [ // 表头
				{ title: '顶级积分类型', dataIndex: 'firstType' },
				{ title: '二级积分类型', dataIndex: 'secondType' },
				{ title: '单项积分', dataIndex: 'singleValue' },
				{ title: '申请数量', dataIndex: 'count' },
				{ title: '总积分', dataIndex: 'totalValue' },
				{ title: `申请人${custom.team || '团队'}`, dataIndex: 'team' },
				{ title: '申请人姓名', dataIndex: 'apply' },
				{ title: '申请时间', dataIndex: 'createdTime' },
				{ title: '审核人', dataIndex: 'audit' },
				{ title: '审核时间', dataIndex: 'auditTime' },
				{ title: '备注', dataIndex: 'remarks' },
				{ title: '积分类型', dataIndex: 'type' },
				{ title: '审核类型', dataIndex: 'auditType' },
				{
					title: '附件', dataIndex: 'attachment',
					render: (text, record) => {
						if (/\.mp4$/.test(text[0])) {
							return (
								<Icon type="video-camera" onClick={this.preview.bind(this, text[0])} title='点击查看视频' />
							)
						} else {
							return text.map(path => {
								return <img src={Host + path} key={path} width='50' onClick={this.preview.bind(this, path)} alt='' title='点击查看大图' />
							})
						}
					}
				},
				{ title: '审核状态', dataIndex: 'status' },
			]
		})
	}

	closeSumBox = () => {
		this.setState({ showSumBox: false })
	}

	componentDidMount() {
		const custom = window.localStorage.getItem('custom') || '{}'
		const userInfo = window.localStorage.getItem('userInfo') || '{}'
		const all = JSON.parse(userInfo).all === undefined ? 0 : JSON.parse(userInfo).all
		this.setState({ custom: JSON.parse(custom), all }, this.initColumns)
		this.getTypeList()
		if (all) {
			this.getDepartmentList()
		} else {
			this.getTeamList()
		}
	}

	getIntegList = (opt) => {
		message.loading('数据加载中...', 0)
		IntegDetail.list(opt)
			.then(res => {
				const { success, msg, resData, rows, sum } = res.data
				if (success) {
					// if (opt.displayType) {
					// 	this.setState({ showSumBox: true, sum: resData })
					// } else {
					const dataSource = resData.map(item => {
						return {
							key: item._id,
							firstType: item.firstType ? item.firstType.name : '',
							secondType: item.secondType ? item.secondType.name : '',
							singleValue: item.singleValue,
							count: item.count,
							totalValue: item.totalValue,
							team: item.team.name,
							apply: item.apply ? item.apply.name : '',
							createdTime: new Date(item.createdTime).toLocaleString(),
							auditTime: item.auditTime && new Date(item.auditTime).toLocaleString(),
							audit: item.audit && item.audit.name,
							remarks: item.remarks,
							type: item.type ? '加分' : '申请',
							auditType: item.auditType ? '手动' : '自动',
							attachment: item.attachment,
							status: (
								item.status ?
									item.status === 1 ? '待审' :
										item.status === 2 ? '已审' : '不批准' :
									'作废'
							)
						}
					})
					this.setState({ dataSource, rows, pages: opt.pages, opt: opt, sum }, () => {
						message.destroy()
						message.success('查询成功!', 3)
					})
					// }
				} else {
					message.destroy()
					message.error(msg, 3)
				}
			})
			.catch(err => {
				console.log(err);
				message.destroy()
				message.error('服务器错误!', 3)
			})
	}

	changePage = (pages) => {
		this.setState({ pages }, () => { this.getIntegList({ ...this.state.opt, pages }) })
	}

	render() {
		const { getFieldDecorator } = this.props.form;
		const { RangePicker } = DatePicker
		const { teamList, custom, userList, types, pages, pageSize, rows, dataSource, columns, showPreview, showSumBox, sum, departmentList, all } = this.state
		const formItemLayout = {
			labelCol: { span: 2 },
			wrapperCol: { span: 6 },
		}
		return (
			<div id='IntegQuery'>
				<Form onSubmit={this.handleSubmit} layout='horizontal'>

					{/* 起止时间 */}
					<Form.Item label='起止时间' {...formItemLayout}>
						<LocaleProvider locale={zh_CN}>
							<RangePicker onChange={this.onDateChange} placeholder={['起始时间', '截止时间']} />
						</LocaleProvider>
					</Form.Item>

					{/* 选择校区 */}
					{all ? (
						<Form.Item label={`选择${custom.department || '校区'}`} {...formItemLayout}>
							{getFieldDecorator('department', {})(
								<Select onChange={this.handleDepartmentChange}>
									{departmentList.map(department => {
										return <Select.Option value={department.id} key={department.id}>{department.name}</Select.Option>
									})}
								</Select>
							)}
						</Form.Item>
					) : null}


					{/* 选择团队 */}
					<Form.Item label={`选择${custom.team || '团队'}`} {...formItemLayout}>
						{getFieldDecorator('team', {})(
							<Select onChange={this.handleTeamChange}>
								{teamList.map(team => {
									return <Select.Option value={team.id} key={team.id}>{team.name}</Select.Option>
								})}
							</Select>
						)}
					</Form.Item>

					{/* 选择教师 */}
					<Form.Item label={`选择${custom.user || '教师'}`} {...formItemLayout}>
						{getFieldDecorator('apply', {})(
							<Select>
								{userList.map(user => {
									return <Select.Option value={user.id} key={user.id}>{user.name}</Select.Option>
								})}
							</Select>
						)}
					</Form.Item>

					{/* 选择积分类型 */}
					<Form.Item label={`积分类型`} {...formItemLayout}>
						{getFieldDecorator('types', {})(
							<Cascader options={types} onChange={this.changeType} changeOnSelect placeholder="积分类型" />
						)}
					</Form.Item>

					{/* 审核类型 */}
					<Form.Item label={`审核类型`} {...formItemLayout}>
						{getFieldDecorator('auditType', {})(
							<Select>
								<Select.Option value={0}>自动</Select.Option>
								<Select.Option value={1}>手动</Select.Option>
							</Select>
						)}
					</Form.Item>

					{/* 审核状态 */}
					<Form.Item label={`审核状态`} {...formItemLayout}>
						{getFieldDecorator('status', {})(
							<Select>
								{/* <Select.Option value={0}>作废</Select.Option> */}
								<Select.Option value={1}>待审</Select.Option>
								<Select.Option value={2}>已审</Select.Option>
								<Select.Option value={3}>不批准</Select.Option>
							</Select>
						)}
					</Form.Item>

					{/* 提交按钮 */}
					<Form.Item style={{ display: 'block' }}>
						<Button type="primary" htmlType="submit">提交查询</Button>
					</Form.Item>
				</Form>

				<div style={{ paddingBottom: '20px' }}>
					总分数：{sum}分
				</div>

				<Table
					columns={columns}
					dataSource={dataSource}
					pagination={{
						current: pages,
						pageSize,
						total: rows,
						onChange: this.changePage
					}}
				/>

				<Modal
					footer={null}
					width={1000}
					style={{ textAlign: 'center' }}
					onCancel={this.closePreview}
					visible={showPreview}
				>
					{this.state.previewContent}
				</Modal>

				{/* <Modal
					title='查询结果'
					footer={null}
					width={300}
					onCancel={this.closeSumBox}
					visible={showSumBox}
				>
					<p>总积分:{sum}</p>
				</Modal> */}
			</div>
		);
	}
}

IntegQuery = Form.create({})(IntegQuery);

export default IntegQuery;