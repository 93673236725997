import React, { Component } from 'react';

// 导入第三方组件
import { Form, Input, Button, message, Select, Popconfirm, Table, Tabs, Tree, Modal } from 'antd'
import { Link } from 'react-router-dom'


// 导入工具类
import { IntegType, User } from '../../../utils/request'

const { TabPane } = Tabs
const { Option } = Select
const { TreeNode } = Tree;

class IntegTypeManage extends Component {

  state = {
    fatherType: [], // 顶级类型列表
    level: 0, // 控制是否顶级分类
    special: 0, // 是否特殊类型 默认 0 -- 否
    appointList: [],
    typeList: [], // 数据列表
    rows: 0, // 总条数
    typeTree: [], // 积分类型树
    pageSize: 10, // 每页条数
    pages: 1, // 当前页数
    columns: [
      { title: '积分类型', dataIndex: 'name' },
      { title: '分数', dataIndex: 'value' },
      { title: '父级分类', dataIndex: 'father' },
      { title: '审核方式', dataIndex: 'auditType' },
      { title: '是否特殊类型', dataIndex: 'special' },
      { title: '指定审核人', dataIndex: 'appoint' },
      { title: '序号', dataIndex: 'order' },
      {
        title: '管理', dataIndex: 'manage',
        render: (text, record) => {
          return (
            this.state.typeList.length ? (
              <div className='userManage clearfix'>
                {/* <Link to={`/integtype/update/${record.key}`} style={{ display: 'inline-block', marginRight: '10px' }}>修改</Link> */}
                <span onClick={() => this.showUpdateModal(record.key)} style={{ display: 'inline-block', marginRight: '10px', color: '#1890ff', cursor: 'pointer' }}>修改</span>
                <Popconfirm title="若作废类型为顶级类型,将影响其所有子类型,是否确定作废?" okText="是" cancelText="否" onConfirm={() => this.disabled(record.key)}>
                  <span style={{ display: 'inline-block', marginRight: '10px', color: '#1890ff', cursor: 'pointer' }}>作废</span>
                </Popconfirm>
                <Popconfirm title="此操作不可恢复,是否确定删除?" okText="是" cancelText="否" onConfirm={() => this.handleDelete(record.key)}>
                  <span style={{ color: '#1890ff', cursor: 'pointer' }}>删除</span>
                </Popconfirm>
              </div>
            ) : null
          )
        },
      }
    ],
    editingKey: '',
    editingInfo: {}
  }

  showUpdateModal = (id) => {
    IntegType.info({ id }).then(res => {
      const { success, resData } = res.data
      if (success) { this.setState({ editingKey: id, editingInfo: resData }) }
    })
  }


  // 添加积分类型
  add = (e) => {
    e.preventDefault();
    const that = this
    const form = this.props.form
    form.validateFields((err, values) => {
      if (err) {
        // 表单错误 退出
        console.log(err);
        return message.error('程序错误!', 3)
      }

      if (values.name.length > 22) {
        return message.warn('积分类型名称最大长度为22个字符!', 3)
      }

      // 处理表单数据 
      const opt = {}
      Object.entries(values).forEach((field) => {
        if (typeof (field[1]) === 'string') {
          // 每一个字段
          const val = field[1].split(' ').join('')

          // 判断是否为空
          if (val !== '') {
            // 如果不为空
            opt[field[0]] = val
          } else {
            // 空字段 重置表单 提示 并退出
            form.resetFields()
            return message.error('无效字符!', 3)
          }
        } else {
          opt[field[0]] = field[1]
        }
      })

      if (opt.level && !opt.fid) {
        return message.error('程序错误!', 3)
      }

      // 发送数据
      IntegType.add(opt)
        .then(function (res) {
          if (res.data.success) {
            form.resetFields()
            message.success('添加成功!', 2);
            if (values.fid === 0) {
              that.getFatherType()
            }
            that.setState({ level: 0, pages: 1 })
            that.getTypeList(1)
            that.setTypeTree()
          } else {
            message.error(res.data.msg, 2)
          }
        })
        .catch(function (error) {
          message.error('服务器错误!', 2);
        });
    });
  }

  // 获取顶级类型列表
  getFatherType = () => {
    IntegType.list({ father: true, pages: 0 })
      .then(res => {
        // 请求成功
        const { success, resData } = res.data
        if (success) {
          // 查询成功
          const fatherType = resData.map(item => {
            return { _id: item._id, name: item.name }
          })
          this.setState({ fatherType })
        }
      })
      .catch(err => {
        // 请求失败
        console.log(err);
      })
  }

  // 获取特殊管理员列表
  getAppointList = () => {
    User.userList({ pages: 0, appoint: true })
      .then(res => {
        const { success, resData } = res.data
        if (success) {
          this.setState({ appointList: resData })
        }
      })
      .catch(err => {
        console.log(err);
      })
  }

  // 获取积分类型列表
  getTypeList = (pages = 1) => {
    IntegType.list({ pages })
      .then(res => {
        const { resData, rows } = res.data
        const typeList = []
        resData.forEach(item => {
          typeList.push({
            name: item.name,
            value: item.value || '无',
            special: item.fid ? item.fid.special ? '是' : '否' : item.special ? '是' : '否',
            appoint: item.level ? item.fid ? item.fid.appoint ? item.fid.appoint.name : '' : item.appoint ? item.appoint.name : '' : '',
            father: item.fid ? item.fid.name : '顶级分类',
            auditType: item.level ? item.auditType ? '手动' : '自动' : '无',
            order: item.order,
            key: item._id
          })
        })
        this.setState({ typeList, rows: rows })
      })
      .catch(err => {
        console.log(err);
      })
  }

  // 设置积分类型树
  setTypeTree = () => {
    IntegType.list({ pages: 0 })
      .then(res => {
        const { success, msg, resData } = res.data
        if (success) {
          // 查询成功
          const typeTree = []

          // 遍历积分类型列表
          resData.forEach(item => {
            if (!item.level) {
              // 如果是顶级类型
              typeTree.push({ title: item.name, id: item._id, children: [] })
            } else {
              // 二级类型 找父级
              typeTree.forEach((i, index) => {
                // if (!item.fid) console.log(item)
                if (i.id === item.fid._id) {
                  typeTree[index].children.push({ title: item.name })
                }
              })
            }
          })

          // 保存到state
          this.setState({ typeTree })
        } else {
          // 查询失败
          message.error(msg, 3)
        }
      })
      .catch(err => {
        // 请求错误
        console.log(err);
      })
  }

  // 积分等级变化
  handleLevelChange = (value) => {
    this.setState({ level: value ? 1 : 0 })
  }

  // 是否特殊类型
  handleSpecialChange = (value) => {
    this.setState({ special: value })
  }

  // 删除积分类型
  handleDelete = (id) => {
    IntegType.delete({ id })
      .then(res => {
        if (res.data.success) {
          message.success('删除成功!', 3)
          this.getTypeList(1)
          this.getFatherType()
          this.setTypeTree()
        } else {
          message.error(res.data.msg, 3)
        }
      })
      .catch('服务器错误!', 3)
  }

  // 作废积分类型
  disabled = (id) => {
    IntegType.disabled({ id })
      .then(res => {
        const { success, msg } = res.data
        if (success) {
          message.success('作废成功!', 3)
          this.getTypeList(1)
          this.getFatherType()
          this.setTypeTree()
        } else {
          message.error(msg, 3)
        }
      })
      .catch(err => {
        console.log(err);
        message.error('程序错误!', 3)
      })
  }

  // 换页
  changePage = (pages) => {
    this.setState({ pages }, this.getTypeList(pages))
  }

  hideUpdateModal = (reload) => {
    this.setState({ editingInfo: {}, editingKey: '' })
    if (reload) { this.getTypeList(this.state.pages) }
  }

  componentDidMount() {
    this.getFatherType()
    this.getTypeList(1)
    this.setTypeTree()
    this.getAppointList()
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { state } = this
    return (
      <div id="IntegTypeManage">
        <Form onSubmit={this.add} layout='inline' style={{ paddingBottom: '20px' }}>

          {/* 父级类型id */}
          <Form.Item>
            {getFieldDecorator('fid', {
              initialValue: 0
            })(
              <Select onChange={this.handleLevelChange} style={{ minWidth: '200px' }}>
                <Option value={0}>顶级分类</Option>
                {this.state.fatherType.map((item, index) => {
                  return (<Option value={item._id} key={index}>{item.name}</Option>)
                })}
              </Select>
            )}
          </Form.Item>

          {/* 类型名称 */}
          <Form.Item>
            {getFieldDecorator('name', {
              rules: [{ required: true, message: '请输入积分类型名称!' }],
            })(
              <Input placeholder='积分类型名称' style={{ width: '360px' }} />
            )}
          </Form.Item>

          {/* 排序序号 */}
          <Form.Item>
            {getFieldDecorator('order', {
              rules: [{ required: true, message: '请输入序号!序号越大,则该积分类型排名越靠前' }], initialValue: 1,
            })(
              <Input type={'number'} placeholder={'序号'} style={{ width: '80px' }} />
            )}
          </Form.Item>

          {/* 审核方式 */}
          {state.level ? (
            <Form.Item>
              {getFieldDecorator('auditType', {
                initialValue: 1,
              })(
                <Select>
                  <Option value={0}>自动</Option>
                  <Option value={1}>手动</Option>
                </Select>
              )}
            </Form.Item>
          ) : null}

          {/* 积分数值 */}
          {state.level ? (
            <Form.Item>
              {getFieldDecorator('value', {
                rules: [{ required: true, message: '请输入分数!' }],
              })(
                <Input type={'number'} placeholder={'分数'} />
              )}
            </Form.Item>
          ) : ''}

          {/* 是否特殊类型 */}
          {!state.level ? (
            <Form.Item>
              {getFieldDecorator('special', {
                initialValue: state.special,
              })(
                <Select onChange={this.handleSpecialChange}>
                  <Option value={0}>普通类型</Option>
                  {state.appointList.length ? <Option value={1}>特殊类型</Option> : null}
                </Select>
              )}
            </Form.Item>
          ) : null}

          {/* 特殊类型 指定审核人 */}
          {!state.level && state.special ? (
            <Form.Item>
              {getFieldDecorator('appoint', {
                initialValue: state.appointList[0] ? state.appointList[0]._id : null,
              })(
                <Select>
                  {state.appointList.map((item, index) => {
                    return (
                      <Option value={item._id} key={index}>{item.name}</Option>
                    )
                  })}
                </Select>
              )}
            </Form.Item>
          ) : null}

          <Form.Item>
            <Button type="primary" htmlType="submit">添加</Button>
          </Form.Item>
        </Form>

        <Tabs defaultActiveKey="1">
          <TabPane tab="积分类型表" key="1">
            <Table
              columns={state.columns}
              dataSource={state.typeList}
              pagination={{
                pageSize: state.pageSize,
                current: state.pages,
                onChange: this.changePage,
                total: state.rows
              }}
            ></Table>
          </TabPane>
          <TabPane tab="积分类型树" key="2">
            <Tree>
              {state.typeTree.map((item, index) => {
                return (
                  <TreeNode title={item.title} key={index} selectable={false}>
                    {item.children.map((item, index) => {
                      return (<TreeNode title={item.title} key={index} selectable={false} />)
                    })}
                  </TreeNode>
                )
              })}
            </Tree>
          </TabPane>
        </Tabs>

        <UpdateModal show={state.editingKey ? true : false} info={state.editingInfo} hide={this.hideUpdateModal}></UpdateModal>
      </div>
    );
  }
}

IntegTypeManage = Form.create({})(IntegTypeManage);

class UpdateModal extends Component {
  update = (e) => {
    e.preventDefault()
    const that = this
    const form = this.props.form
    form.validateFields((err, values) => {
      if (!err) {
        values.name = values.name.split(' ').join()
        if (!values.name) { return message.warn('名称不可为空!') }

        IntegType.update({ ...values, id: that.props.info._id }).then(res => {
          const { success, msg } = res.data
          if (success) {
            that.props.hide(1)
            message.success('修改成功!', 3)
          } else {
            message.error(msg, 3)
          }
        })
          .catch(err => {
            console.log(err);
            message.error('程序错误!', 3)
          })
      }
    })
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const { show, info, hide } = this.props
    return (
      <Modal
        visible={show}
        title='修改'
        footer={null}
        onCancel={hide}
        destroyOnClose={true}
      >
        <Form onSubmit={this.update}>
          {/* 名称 */}
          <Form.Item label='名称'>
            {getFieldDecorator('name', {
              initialValue: info.name || '',
              rules: [{ required: true, message: '不可为空!' }]
            })(
              <Input />
            )}
          </Form.Item>

          {/* 积分 */}
          {info.level ? <Form.Item label='分数'>
            {getFieldDecorator('value', {
              initialValue: info.value || 0,
              rules: [{ required: true, message: '不可为空!' }]
            })(
              <Input type='number' />
            )}
          </Form.Item> : null}

          {/* 审核 */}
          {info.level ? <Form.Item label='审核'>
            {getFieldDecorator('auditType', {
              initialValue: info.auditType || 1,
              rules: [{ required: true, message: '不可为空!' }]
            })(
              <Select>
                <Option value={0}>自动</Option>
                <Option value={1}>手动</Option>
              </Select>
            )}
          </Form.Item> : null}

          {/* 序号 */}
          <Form.Item label='序号'>
            {getFieldDecorator('order', {
              initialValue: info.order || 1,
              rules: [{ required: true, message: '不可为空!' }]
            })(
              <Input type='number' />
            )}
          </Form.Item>

          <Form.Item>
            <Button htmlType='submit'>提交修改</Button>
          </Form.Item>
        </Form>

      </Modal>
    )
  }
}
UpdateModal = Form.create({})(UpdateModal);

export default IntegTypeManage;