import React, { Component } from 'react';
import './index.scss'

// 导入第三方组件
import { Link, Redirect } from 'react-router-dom'
import { message } from 'antd'

// 导入工具类
import { User, Host } from '../../../utils/request'

class Hd extends Component {
  state = {
    isLogout: false,
    userInfo: {}
  }

  // 注销登录
  logout = () => {
    User.logout()
      .then(res => {
        // 注销成功
        if (res.data.success) { this.setState({ isLogout: true }) }
      })
      .catch(err => {
        // 请求错误
        console.log(err);
        message.error('程序错误!', 3)
      })
  }

  componentDidMount() {
    // 接收用户信息核自定义名称
    const { custom, userInfo } = this.props

    // 处理用户信息
    const newUserInfo = {
      nickname: userInfo.name,
      avatar: userInfo.avatar,
      department: userInfo.department && userInfo.department.name,
      team: userInfo.team && userInfo.team.name,
    }

    switch (userInfo.identity) {
      case 0: newUserInfo.identity = '平台管理员'
        break
      case 1: newUserInfo.identity = `${custom.root || '学校'}管理员`
        break
      case 2: newUserInfo.identity = `特殊类型管理员`
        break
      case 3: newUserInfo.identity = `${custom.department || '校区'}管理员`
        break
      case 4: newUserInfo.identity = `${custom.user || '教师'}`
        break
      default: break
    }

    // 保存用户信息到state
    this.setState({ userInfo: newUserInfo })
  }

  render() {
    const { custom } = this.props
    const { userInfo } = this.state
    if (this.state.isLogout === true) {
      return (<Redirect to={{ pathname: '/login' }}></Redirect>)
    } else {
      return (
        <div id='Hd' className='clearfix'>
          <h1 className="logo"><Link to='/'>双积分管理系统</Link></h1>

          <div className="userInfo">
            {/* 头像 */}
            {userInfo.avatar && (
              <span className='avatar item'>
                <img src={Host + userInfo.avatar} alt="" />
              </span>
            )}
            <span className='item'>姓名：{userInfo.nickname}</span>
            <span className="item">权限：{userInfo.identity}</span>
            {userInfo.department && <span className='item'>{custom.department || '校区'}：{userInfo.department}</span>}
            {userInfo.team && <span className='item'>{custom.team || '团队'}：{userInfo.team}</span>}
          </div>

          <div className="nav">
            <Link className='btn' to={'/user/personal/' + userInfo.nickname}>个人信息</Link>
            <span className='btn' onClick={this.logout}>退出登录</span>
          </div>
        </div>
      )
    }
  }
}
export default Hd;