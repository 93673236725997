import React, { Component } from 'react';

// 导入组件
import { Table } from 'antd'

// 导入工具类
import { User, Custom } from '../../../utils/request'

class TeamMember extends Component {
  state = {
    custom: {}, // 自定义名称
    columns: [
      { title: '姓名', dataIndex: 'name' },
      { title: '状态', dataIndex: 'used' }
    ], // 用户列表表头
    dataSource: [], // 用户列表
  }

  getTeamUser = () => {
    User.teamMember()
      .then(res => {
        const dataSource = []
        res.data.resData.forEach(item => {
          dataSource.push({ name: item.name, used: item.used ? '启用' : '停用', key: item._id })
        })
        this.setState({ dataSource, rows: res.data.rows })
      })
      .catch(err => {
        console.log(err);
      })
  }

  getCustom = () => {
    Custom.get()
      .then(res => {
        const { success, resData, msg } = res.data
        if (success) {
          this.setState({ custom: resData })
        } else {
          console.log(msg);
        }
      })
      .catch(err => {
        console.log(err);
      })
  }

  componentDidMount() {

    // 获取自定义名称
    this.getCustom()

    // 获取团队成员列表
    this.getTeamUser()
  }

  render() {
    const { dataSource, columns } = this.state
    return (
      <div id="TeamMember">
        {/* 用户列表 */}
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
        />
      </div>
    )
  }
}

export default TeamMember;