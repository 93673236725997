import React, { Component } from 'react';
import { Form, Input, Button, message, Select } from 'antd'

const Option = Select.Option

class selectUserForm extends Component {

  selectUser = (e) => {
    e.preventDefault()
    const that = this
    const { form, selectUser } = this.props
    form.validateFields((err, values) => {
      if (!err) {
        selectUser(values)
      }
    })
  }

  render() {
    const { identity, custom } = this.props
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Form onSubmit={this.selectUser} layout='inline' style={{ paddingBottom: '20px' }}>
          {/* 查询用户的学校 校区 团队 姓名 */}
          <Form.Item>
            {getFieldDecorator('selectType', {
              initialValue: 2
            })(
              <Select>
                {identity === 3 ? (<Option value={1}>{custom.team || '团队'}</Option>) : null}
                <Option value={2}>姓名</Option>
              </Select>
            )}
          </Form.Item>

          {/* 姓名 */}
          <Form.Item>
            {getFieldDecorator('selectName', {
              rules: [{ required: true, message: '请输入要搜索的名称!' }],
            })(
              <Input placeholder="要搜索的名称" />
            )}
          </Form.Item>

          {/* 查询按钮 */}
          <Form.Item>
            <Button type="primary" htmlType="submit">查询用户</Button>
          </Form.Item>
        </Form>
      </div>
    )
  }
}

selectUserForm = Form.create({})(selectUserForm);

export default selectUserForm;