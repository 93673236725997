import React, { Component } from 'react';
import './index.scss'
import { Redirect } from 'react-router-dom'
import { Form, Icon, Input, Button, message } from 'antd';

// 引入工具类
import { User } from '../../utils/request'

class Login extends Component {
  state = { loginStatus: false }

  // 登陆
  handleSubmit = (e) => {
    e.preventDefault();
    const that = this
    const { form } = this.props
    form.validateFields((err, values) => {
      if (err) {
        // 表单错误 提示 并返回
        console.log(err);
        return message.error('程序错误!', 3)
      }

      // 处理表单数据 
      const opt = {
        uname: values.uname.split(' ').join(''),
        pwd: values.pwd.split(' ').join(''),
      }

      // 如果用户名或密码为空 清空表单 并提示
      if (!opt.uname || !opt.pwd) {
        form.resetFields()
        return message.warn('用户名或密码不能为空!', 3)
      }

      // 垃圾文件对象
      opt.upload = window.localStorage.getItem('upload')

      // 提交账号密码
      User.login(opt)
        .then(res => {
          // 判断登录状态
          if (res.data.success) {
            if (res.data.resData.remarks) {
              return message.warn(res.data.resData.remarks, 3)
            }
            // 登录成功 清除垃圾文件对象 并将登陆状态改为true
            window.localStorage.removeItem('upload')
            window.localStorage.setItem('uname', opt.uname)
            that.setState({ loginStatus: true })
          } else {
            // 登录失败 提示
            message.error(res.data.msg, 3)
          }
        })
        .catch(err => {
          console.log(err)
          message.error('程序错误!', 3)
        });
    });
  }

  componentDidMount() {
    const uname = window.localStorage.getItem('uname') || ''
    this.setState({ uname })
  }


  render() {
    const { getFieldDecorator } = this.props.form;
    const { uname } = this.state

    // 判断登陆状态
    if (this.state.loginStatus) {
      // 登陆成功 重定向到首页
      return <Redirect to={{ pathname: "/" }} />
    } else {
      // 未登录 显示登陆框
      return (
        <div id="LoginWrap">

          <img className='logo' src="../../imgs/logo.jpg" alt="#" />

          <Form onSubmit={this.handleSubmit} id="LoginForm">

            {/* 账号 */}
            <Form.Item>
              {getFieldDecorator('uname', {
                rules: [{ required: true, message: '请输入账号!' }], initialValue: uname
              })(
                <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="账号" />
              )}
            </Form.Item>

            {/* 密码 */}
            <Form.Item>
              {getFieldDecorator('pwd', {
                rules: [{ required: true, message: '请输入密码!' }],
              })(
                <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="密码" />
              )}
            </Form.Item>

            {/* 登陆按钮 */}
            <Form.Item>
              <Button type="primary" htmlType="submit" block>登录</Button>
            </Form.Item>
          </Form>

          <div className='copyRight'>
            版权所有：海贝教育连锁
            <p>备案号：<a href="http://www.beian.miit.gov.cn/">辽ICP备16015411号</a></p>
          </div>
        </div>
      );
    }
  }
}

Login = Form.create({})(Login);

export default Login;