import React, { Component } from 'react';

import { Form, Input, Button, message } from 'antd'

import { Team } from '../../../utils/request'

class TeamUpdate extends Component {
  state = {
    id: this.props.match.params.id,
    teamInfo: {}
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const that = this
    const { form } = this.props
    form.validateFields((err, values) => {
      if (err) {
        console.log(err);
        return message.error('程序错误!', 3)
      }

      Team.update({id: this.state.id, ...values})
        .then(res => {
          const { success, msg } = res.data
          if (success) {
            message.success('修改成功!', 3)
            that.getTeamInfo()
          } else {
            message.error(msg, 3)
          }
        })
        .catch(err => {
          console.log(err);
          message.error('程序错误!', 3)
        })
    })
  }

  getTeamInfo = () => {
    Team.info({ id: this.state.id })
      .then(res => {
        const { success, msg, resData } = res.data
        if (success) {
          this.setState({ teamInfo: resData })
        } else {
          message.error(msg, 3)
        }
      })
      .catch(err => {
        console.log(err);
        message.error('程序错误!', 3)
      })
  }

  componentDidMount() {
    this.getTeamInfo()
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div id='TeamUpdate'>
        <Form onSubmit={this.handleSubmit} layout='inline' style={{ paddingBottom: '20px' }}>
          <Form.Item>
            {getFieldDecorator('name', {
              rules: [{ required: true, message: '名称不能为空!' }],initialValue: this.state.teamInfo.name
            })(
              <Input placeholder='名称' />
            )}
          </Form.Item>

          <Form.Item>
            <Button type='primary' htmlType='submit'>提交修改</Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

TeamUpdate = Form.create({})(TeamUpdate);


export default TeamUpdate;