import React, { Component } from 'react';

// 导入第三方组件
import { Form, Input, Button, Upload, Icon, message } from 'antd'

// 导入工具类
import { User, Api, Host } from '../../../utils/request'

class PersonalInfo extends Component {
  state = {
    avatar: '',
    loading: false,
    name: ''
  }

  // 头像上传服务器之前 先过滤一次
  beforeUpload = (file) => {
    // file -- 文件信息

    // 限制文件大小为1MB
    if (file.size > 1024 * 1024) {
      message.warn('头像最大为1M!建议尺寸为240*240像素!', 3)
      return false
    }
  }

  // 获取个人信息
  getPersonalInfo = () => {
    User.personalInfo()
      .then(res => {
        const { success, msg, resData } = res.data
        if (success) {
          this.setState({ name: resData.name, avatar: resData.avatar })
        } else {
          message.error(msg, 3)
        }
      })
      .catch(err => {
        console.log(err);
        message.error('程序错误!', 3)
      })
  }

  // 处理文件状态变化
  handleUploadChange = (info) => {
    const status = info.file.status

    // 判断状态已完成 并且上传成功
    if (status === 'done' && info.file.response.success) {
      message.success('头像上传成功!', 3)
      this.setState({ avatar: info.file.response.resData, loading: false })
    }

    if (status === 'error') {
      message.error('头像上传失败!', 3)
      this.setState({ loading: false })

    }

    if (status === 'uploading') {
      this.setState({ loading: true })
    }
  }

  // 提交表单
  handleSubmit = (e) => {
    e.preventDefault()
    const that = this
    const form = this.props.form
    form.validateFields((err, values) => {
      if (!err) {
        const { name, pwd1, pwd2 } = values // 表单数据
        const _name = name.split(' ').join('')
        if (!_name) { // 如果用户名为空格 重置表单 并返回
          form.resetFields()
          return message.warn('用户名不能为空!', 3)
        } else {
          values.name = _name

          // 判断是否要更改密码
          if (pwd1 || pwd2) {
            if (pwd1 !== pwd2) {
              return message.warn('两次密码不一致!', 3)
            }

            // 去除空格
            const _pwd1 = pwd1.split(' ').join('')
            const _pwd2 = pwd2.split(' ').join('')

            // 正则 匹配密码
            const pwdReg = /^[A-Za-z0-9_]{3,16}$/

            // 校验密码
            if (!pwdReg.test(_pwd1)) { // 密码格式不正确
              form.resetFields()
              return message.warn('密码必须为3-16位大小写字母、数字或下划线!')
            } else if (_pwd1 !== _pwd2) { // 两次密码不一致
              form.resetFields()
              return message.warn('两次密码输入不一致!请重新输入')
            } else { // 密码正确
              values.pwd1 = _pwd1
              values.pwd2 = _pwd2
            }
          }

          // 发送请求
          User.personalUpdate(values)
            .then(res => {
              // 请求成功
              const { success, msg } = res.data
              if (success) {
                // 修改成功
                message.success('修改成功!', 3)
                that.setState({ name: values.name }, form.resetFields())
              } else {
                // 修改失败
                message.error(msg, 3)
              }
            })
            .catch(err => {
              // 请求错误
              console.log(err);
              message.error('程序错误!', 3)
            })
        }
      }
    })
  }


  componentDidMount() {
    this.getPersonalInfo()
  }


  render() {
    const { getFieldDecorator } = this.props.form;
    const { avatar, loading, name } = this.state
    const uploadButton = (
      <div>
        <Icon type={loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">上传头像</div>
      </div>
    );
    return (
      <div id="PersonalInfo">
        <Upload
          action={Api + '/user/uploadavatar'} // 头像上传接口
          accept='.jpg,.png' // 限制头像文件类型
          listType="picture-card"
          withCredentials={true} // 携带cookie
          showUploadList={false} // 不显示上传的文件列表
          beforeUpload={this.beforeUpload} // 上传头像之前 先检测头像文件大小
          onChange={this.handleUploadChange}
        >
          {avatar ? (<img src={Host + avatar} width='100' alt="" />) : uploadButton}
        </Upload>

        <Form onSubmit={this.handleSubmit} layout='inline'>

          {/* 姓名 */}
          <Form.Item>
            {getFieldDecorator('name', {
              initialValue: name, rules: [{ required: true, message: '姓名不能为空!' }]
            })(
              <Input placeholder='姓名' />
            )}
          </Form.Item>

          {/* 新密码 */}
          <Form.Item>
            {getFieldDecorator('pwd1', {
            })(
              <Input type={'password'} placeholder='新密码' />
            )}
          </Form.Item>

          {/* 确认密码 */}
          <Form.Item>
            {getFieldDecorator('pwd2', {
            })(
              <Input type={'password'} placeholder='确认密码' />
            )}
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">确认修改</Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

PersonalInfo = Form.create({})(PersonalInfo);

export default PersonalInfo;