import React, { Component } from 'react';

// 导入组件
import { Link } from 'react-router-dom'
import { Form, Input, Button, message, Select, Table, Popconfirm, Switch } from 'antd'
import SelectUserForm from '../Select'

// 导入工具类
import { User, Department, Team } from '../../../utils/request'

const Option = Select.Option

class UsersManage extends Component {
  state = {
    identity: null, // 用户身份
    custom: {}, // 自定义名称
    departmentList: [], // 部门列表
    teamList: [], // 团队列表
    columns: [], // 用户列表表头
    userIdentity: 3, // 要添加用户的权限 用来控制是否显示校区列表 默认3 -- 普通校区管理员 2 -- 特权管理员
    userList: [], // 用户列表
    userRows: 1, // 用户列表数据总条数
    pageSize: 10, // 每页显示条数
    pages: 1, // 当前页数
    userUsed: true, // 查询用户状态
  }

  // 换页事件 请求数据
  changePage = (pages) => {
    this.setState({ pages }, () => { this.getUserList() })
  }

  selectUser = (values) => {
    const that = this
    User.userList({ pages: 0 })
      .then(res => {
        const { success, msg, resData } = res.data
        if (success) {
          const list = []
          const userList = []
          const { selectType, selectName } = values
          resData.forEach(item => { // 遍历后台数据
            // 拿到用户的身份
            let { identity } = that.state

            // 判断用户身份 设置相应的表格数据
            if (identity === 0) {
              list.push({
                name: item.root.name,
                used: item.root.used ? '启用' : '停用',
                uname: item.uname,
                pwd: item.pwd,
                createdTime: new Date(item.root.createdTime).toLocaleDateString(),
                endTime: new Date(item.root.endTime).toLocaleDateString(),
                remarks: item.root.remarks,
                key: item.root._id
              })
            } else if (identity === 1) {
              list.push({
                department: item.department ? item.department.name : '无',
                identity: item.identity === 2 ? '特殊类型管理员' : `${that.state.custom.department || '校区'}管理员`,
                name: item.name,
                used: item.used ? '启用' : '停用',
                uname: item.uname,
                pwd: item.pwd,
                key: item._id
              })
            } else if (identity === 3) {
              list.push({
                team: item.team.name,
                name: item.name,
                used: item.used ? '启用' : '停用',
                uname: item.uname,
                pwd: item.pwd,
                key: item._id
              })
            }
          })

          list.forEach(item => {
            switch (selectType) {
              case 1:
                if (item.team.indexOf(selectName) !== -1) userList.push(item)
                break;
              case 2:
                if (item.name.indexOf(selectName) !== -1) userList.push(item)
                break;
              default:
                break
            }
          })
          if (userList.length) {
            message.success('查询成功!', 3)
            that.setState({ userList, userRows: 1 })
          } else {
            message.warn('未查询到用户!', 3)
          }
        }
      })
  }

  // 添加用户
  addUser = (e) => {
    e.preventDefault();
    const that = this
    const { custom } = this.state
    const { form } = this.props
    form.validateFields((err, values) => {
      if (err) {
        console.log(err);
        return message.error('程序错误!', 3)
      }

      // 处理表单数据 
      const opt = {}
      const entries = Object.entries(values)

      for (let i = 0; i < entries.length; i++) {
        const field = entries[i]
        if (typeof (field[1]) === 'string') {
          // 每一个字段
          const val = field[1].split(' ').join('')

          // 判断是否为空
          if (val !== '') {
            // 如果不为空
            if (field[0] === 'pwd') {
              // 如果是密码字段 验证格式
              const pwdReg = /^[A-Za-z0-9_]{3,16}$/
              if (pwdReg.test(val)) {
                // 密码格式正确
                opt[field[0]] = val
              } else {
                // 密码格式不正确 重置表单 提示 并退出
                form.resetFields()
                return message.error('密码格式为3-16位大小写字母、数字或下划线!', 3)
              }
            } else {
              opt[field[0]] = val
            }
          } else {
            // 空字段 重置表单 提示 并退出
            form.resetFields()
            return message.error('无效字符!', 3)
          }
        } else {
          opt[field[0]] = field[1]
        }
      }

      // 要添加部门管理员 且还未设置部门
      if (opt.identity === 3 && !opt.department) return message.warn(`请先添加${custom.department || '校区'}!`, 3)

      // 要添加用户 且还未设置团队
      if (this.state.identity === 3 && !opt.team) return message.warn(`请先添加${custom.team || '团队'}!`, 3)

      // 添加用户
      User.addUser(opt)
        .then(function (res) {
          if (res.data.success) {
            message.success('添加成功!', 3);
            that.setState({ userIdentity: 3, pages: 1 })
            form.resetFields() // 清空表单数据
            that.getUserList(1) // 重新获取用户列表
          } else { message.error(res.data.msg, 3) }
        })
        .catch(function (err) {
          console.log(err);
          message.error('程序错误!', 3);
        });

    });
  }

  // 删除用户
  deleteUser = (id) => {
    User.deleteUser({ id })
      .then(res => {
        const { success, msg } = res.data
        if (success) {
          message.success('删除成功!', 3)
          this.setState({ pages: 1 })
          this.getUserList(1)
        } else {
          message.error(msg, 3)
        }
      })
      .catch(err => {
        console.log(err);
        message.error('程序错误!', 3)
      })
  }

  // 获取校区列表
  getDepartmentList = () => {
    // 请求数据
    Department.list({ pages: 0 })
      .then(res => {
        const { success, msg, resData } = res.data
        if (success) {
          // 查询成功
          const departmentList = resData.map(department => ({ id: department._id, name: department.name }))
          this.setState({ departmentList })
        } else {
          // 查询失败
          message.error(msg, 3)
        }
      })
      .catch(err => {
        // 请求错误
        console.log(err);
      })
  }

  // 获取用户列表
  getUserList = () => {
    const that = this
    const { pages, userUsed } = this.state
    message.loading('数据加载中...')
    User.userList({ pages, used: userUsed })
      .then(res => {
        const { resData, subData, rows, success, msg } = res.data // 后台数据 总条数
        if (success) {
          const userList = [] // 存放处理后的数据
          resData.forEach(item => { // 遍历后台数据
            // 拿到用户的身份
            let { identity } = this.state

            // 判断用户身份 设置相应的表格数据
            if (identity === 0) {
              let _user = {
                id: item._id,
                name: item.root.name,
                used: item.root.used ? '启用' : '停用',
                uname: item.uname,
                pwd: item.pwd,
                createdTime: new Date(item.root.createdTime).toLocaleDateString(),
                endTime: new Date(item.root.endTime).toLocaleDateString(),
                remarks: item.root.remarks,
                key: item.root._id
              }
              _user.lastTime = new Date(subData[_user.id].lastTime).toLocaleDateString()
              _user.sum = subData[_user.id].sum
              userList.push(_user)
            } else if (identity === 1) {
              userList.push({
                department: item.department ? item.department.name : '无',
                identity: item.identity === 2 ? '特殊类型管理员' : `${that.state.custom.department || '校区'}管理员`,
                name: item.name,
                used: item.used ? '启用' : '停用',
                uname: item.uname,
                pwd: item.pwd,
                key: item._id
              })
            } else if (identity === 3) {
              userList.push({
                team: item.team.name,
                name: item.name,
                used: item.used ? '启用' : '停用',
                uname: item.uname,
                pwd: item.pwd,
                key: item._id
              })
            }
          })

          that.setState({ userList, userRows: rows }, () => { message.destroy() })
        } else {
          message.error(msg, 3)
        }
      })
      .catch(err => {
        console.log(err);
        message.destroy()
        message.error('程序错误!', 3)
      })
  }

  // 初始化表头
  initColumns = () => {
    // 用户权限
    let { identity, custom } = this.state

    // 表头
    let columns = []

    // admin用户
    if (identity === 0) {
      columns = [
        { title: '用户名', dataIndex: 'name' },
        { title: '状态', dataIndex: 'used' },
        { title: '账号', dataIndex: 'uname' },
        { title: '密码', dataIndex: 'pwd' },
        { title: '授权时间', dataIndex: 'createdTime' },
        { title: '到期时间', dataIndex: 'endTime' },
        { title: '总积分', dataIndex: 'sum' },
        { title: '最后使用时间', dataIndex: 'lastTime' },
        { title: '备注', dataIndex: 'remarks' },
        {
          title: '管理', dataIndex: 'manage',
          render: (text, record) => (
            this.state.userList.length >= 1 ? (
              <Link to={`/user/updatechild/${record.key}`}>修改</Link>
            ) : null
          )
        }
      ]
    } else if (identity === 1) {
      columns = [
        { title: custom.department || '校区', dataIndex: 'department' },
        { title: '权限', dataIndex: 'identity' },
        { title: '姓名', dataIndex: 'name' },
        { title: '状态', dataIndex: 'used' },
        { title: '账号', dataIndex: 'uname' },
        { title: '密码', dataIndex: 'pwd' },
        {
          title: '管理', dataIndex: 'manage',
          render: (text, record) => (
            <div>
              <Link to={`/user/updatechild/${record.key}`}>修改</Link>
            </div>
          ),
        }
      ]
    } else if (identity === 3) {
      columns = [
        { title: custom.team || '团队', dataIndex: 'team' },
        { title: '姓名', dataIndex: 'name' },
        { title: '状态', dataIndex: 'used' },
        { title: '账号', dataIndex: 'uname' },
        { title: '密码', dataIndex: 'pwd' },
        {
          title: '管理', dataIndex: 'manage',
          render: (text, record) => (
            <div>
              <Link to={`/user/updatechild/${record.key}`} style={{ display: 'inline-block', marginRight: '10px' }}>修改</Link>
              <Popconfirm title="确定删除?" okText="是" cancelText="否" onConfirm={() => this.deleteUser(record.key)}>
                <span style={{ color: '#1890ff', cursor: 'pointer' }}>删除</span>
              </Popconfirm>
            </div>
          ),
        }
      ]
    }

    this.setState({ columns }, this.getUserList(1))
  }

  // 用户身份改变 
  handleIdentityChange = (value) => {
    if (this.userIdentity !== value) {
      this.setState({ userIdentity: value })
    }
  }

  // 获取团队列表
  getTeamList = (pages = 0) => {
    // 请求数据
    Team.list({ pages })
      .then(res => {
        const { success, msg, resData } = res.data
        if (success) {
          // 查询成功
          const teamList = resData.map(team => ({ id: team._id, name: team.name }))
          this.setState({ teamList })
        } else {
          // 查询失败
          message.error(msg, 3)
        }
      })
      .catch(err => {
        // 请求错误
        console.log(err);
      })
  }

  componentDidMount() {
    const userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
    const { identity } = userInfo

    if (identity === 0) {
      this.setState({ identity }, this.initColumns)
    } else {
      const custom = window.localStorage.getItem('custom') || '{}'
      this.setState({ custom: JSON.parse(custom), identity }, this.initColumns)

      if (identity === 1) {
        this.getDepartmentList()
      }
      if (identity === 3) {
        this.getTeamList()
      }
    }
  }

  userUsedChange = (checked) => {
    this.setState({ userUsed: checked, pages: 1 }, () => {
      this.getUserList()
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { custom, identity, userIdentity, departmentList, teamList, userList, columns, pages, pageSize, userRows } = this.state
    return (
      <div id="UsersManage">
        <Form onSubmit={this.addUser} layout='inline' style={{ paddingBottom: '20px' }}>

          {/* 姓名 */}
          <Form.Item>
            {getFieldDecorator('name', {
              rules: [{ required: true, message: '请输入姓名!' }],
            })(
              <Input placeholder="姓名" />
            )}
          </Form.Item>

          {/* 账号 */}
          <Form.Item>
            {getFieldDecorator('uname', {
              rules: [{ required: true, message: '请输入账号!' }],
            })(
              <Input placeholder="账号" />
            )}
          </Form.Item>

          {/* 密码 */}
          <Form.Item>
            {getFieldDecorator('pwd', {
              rules: [{ required: true, message: '请输入密码!' }],
            })(
              <Input type='password' placeholder="密码" />
            )}
          </Form.Item>

          {/* 是否特权管理员 */}
          {identity === 1 ? (
            <Form.Item>
              {getFieldDecorator('identity', {
                initialValue: 3
              })(
                <Select onChange={this.handleIdentityChange}>
                  <Option value={2}>特殊类型管理员</Option>
                  <Option value={3}>{custom.department || '校区'}管理员</Option>
                </Select>
              )}
            </Form.Item>
          ) : ''}

          {/* 部门列表 */}
          {departmentList.length ? (identity === 1 && userIdentity === 3) ? (
            <Form.Item>
              {getFieldDecorator('department', {
                initialValue: departmentList[0].id
              })(
                <Select>
                  {departmentList.map((item, index) => {
                    return (<Option value={item.id} key={index}>{item.name}</Option>)
                  })}
                </Select>
              )}
            </Form.Item>
          ) : '' : ''}

          {/* 团队列表 */}
          {teamList.length ? (identity === 3) ? (
            <Form.Item>
              {getFieldDecorator('team', {
                initialValue: teamList[0].id
              })(
                <Select>
                  {teamList.map((item, index) => {
                    return (<Option value={item.id} key={index}>{item.name}</Option>)
                  })}
                </Select>
              )}
            </Form.Item>
          ) : '' : ''}

          {/* 提交按钮 */}
          <Form.Item>
            <Button type="primary" htmlType="submit">添加</Button>
          </Form.Item>
        </Form>

        <SelectUserForm identity={identity} custom={custom} selectUser={this.selectUser} />

        <div style={{ paddingBottom: "20px" }}>
          <Switch checkedChildren="启用用户" unCheckedChildren="停用用户" defaultChecked onChange={this.userUsedChange} />
        </div>

        {/* 用户列表 */}
        <Table
          columns={columns}
          dataSource={userList}
          pagination={{
            pageSize: pageSize,
            current: pages,
            onChange: this.changePage,
            total: userRows
          }}
        />
      </div>
    )
  }
}

UsersManage = Form.create({})(UsersManage);

export default UsersManage;

