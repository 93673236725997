import React, { Component } from 'react';

// 导入组件
import { Form, Input, Button, message, Select } from 'antd'

// 导入工具类
import { User, Custom, Team } from '../../../utils/request'
import { pwdRE } from '../../../utils/RegExp'

const Option = Select.Option
const creatHistory = require('history').createHashHistory 
const history = creatHistory();

class UpdateChild extends Component {
  state = {
    id: this.props.match.params.id, // 要修改的子级用户id
    childInfo: {}, // 要修改的子级用户信息
    identity: null, // 用户的身份,
    custom: {}, // 自定义名称
    teams: []
  }

  // 提交表单
  handleSubmit = (e) => {
    e.preventDefault()
    const that = this
    const { form } = this.props
    form.validateFields((err, values) => {
      if (!err) {
        // 处理表单数据 
        const opt = {}
        const entries = Object.entries(values)

        for (let i = 0; i < entries.length; i++) {
          const field = entries[i]
          if (typeof (field[1]) === 'string') {

            // 每一个字段
            const val = field[1].split(' ').join('')

            // 判断是否为空
            if (val !== '') {
              // 如果不为空
              if (field[0] === 'pwd') {
                // 如果是密码字段 验证格式
                
                if (pwdRE.test(val)) {
                  // 密码格式正确
                  opt[field[0]] = val
                } else {
                  // 密码格式不正确 重置表单 提示 并退出
                  form.resetFields()
                  return message.error('密码格式为3-20位大小写字母、数字或下划线!', 3)
                }
              } else {
                opt[field[0]] = val
              }
            } else {
              if (field[0] === 'remarks') {
                opt[field[0]] = val
              } else {
                // 空字段 重置表单 提示 并退出
                form.resetFields()
                return message.error('无效字符!', 3)
              }
            }
          } else {
            opt[field[0]] = field[1]
          }
        }

        User.updateChild({ ...opt, id: this.state.id })
          .then(res => {
            const { success, msg } = res.data
            if (success) { // 如果修改成功 提示成功 并返回用户列表
              message.success('修改成功!', 3)
              history.goBack();
              // that.getChildInfo()
            } else { message.error(msg, 3) }
          })
          .catch(err => {
            console.log(err);
            message.error('服务器错误!', 3)
          })
      }
    })
  }

  getTeams = () => {
    Team.list({ pages: 0 })
      .then(res => {
        const { success, msg, resData } = res.data
        if (success) {
          this.setState({
            teams: resData
          })
        } else {
          // 查询失败 
          message.error(msg, 3)
        }
      })
      .catch(err => {
        // 请求错误
        console.log(err);
        message.error('程序错误!', 3)
      })
  }

  // 获取子级用户信息
  getChildInfo = () => {
    // 请求数据
    User.childInfo({ id: this.state.id })
      .then(res => {
        const { success, msg, resData, identity } = res.data
        // 请求成功
        if (success) {
          // 查询成功
          this.setState({ childInfo: resData, identity })
          if (identity === 3) {
            this.getTeams()
          }
        } else {
          // 查询失败 
          message.error(msg, 3)
        }
      })
      .catch(err => {
        // 请求错误
        console.log(err);
        message.error('程序错误!', 3)
      })
  }

  getCustom = () => {
    Custom.get()
      .then(res => {
        const { success, resData, msg } = res.data
        // 请求成功
        if (success) {
          // 查询成功 保存到state 并请求用户信息
          this.setState({ custom: resData || {} }, this.getChildInfo())
        } else {
          // 查询失败
          console.log(msg);
          message.error(msg, 3)
        }
      })
      .catch(err => {
        // 请求错误
        console.log(err);
        message.error('程序错误!', 3)
      })
  }

  componentDidMount() {
    // 组件加载完成时 请求要修改的用户数据
    this.getCustom()
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { childInfo, custom, identity, teams } = this.state

    if (identity === 0) { // 身份为admin时
      return (
        <div id="UpdateChild">
          <Form onSubmit={this.handleSubmit} layout='inline'>

            {/* 学校名称 */}
            <Form.Item label={'学校名称'}>
              {getFieldDecorator('rootName', {
                initialValue: childInfo.root.name
              })(
                <Input placeholder="学校名称" />
              )}
            </Form.Item>

            {/* 账号 */}
            <Form.Item label={'账号'}>
              {getFieldDecorator('uname', {
                initialValue: childInfo.uname
              })(
                <Input placeholder="账号" disabled />
              )}
            </Form.Item>

            {/* 密码 */}
            <Form.Item label={'密码'}>
              {getFieldDecorator('pwd', {
                rules: [{}], initialValue: childInfo.pwd
              })(
                <Input placeholder="密码" />
              )}
            </Form.Item>

            {/* 状态 是否启用 */}
            <Form.Item label={'状态'}>
              {getFieldDecorator('used', {
                initialValue: childInfo.root.used
              })(
                <Select>
                  <Option value={1}>启用</Option>
                  <Option value={0}>停用</Option>
                </Select>
              )}
            </Form.Item>

            {/* 授权时间 */}
            <Form.Item label={'授权时间'}>
              {getFieldDecorator('createdTime', {
                initialValue: new Date(childInfo.root.createdTime).toLocaleDateString()
              })(
                <Input placeholder='授权时间' disabled />
              )}
            </Form.Item>

            {/* 到期时间 */}
            <Form.Item label={'到期时间'}>
              {getFieldDecorator('endTime', {
                rules: [{ required: true, msg: '请输入到期时间!' }],
                initialValue: new Date(childInfo.root.endTime).toLocaleDateString()
              })(
                <Input placeholder='到期时间' />
              )}
            </Form.Item>

            {/* 备注留言 */}
            <Form.Item label={'备注留言'}>
              {getFieldDecorator('remarks', {
                initialValue: childInfo.root.remarks || ''
              })(
                <Input placeholder='备注留言' />
              )}
            </Form.Item>

            {/* 提交按钮 */}
            <Form.Item>
              <Button type="primary" htmlType="submit">确认修改</Button>
            </Form.Item>
          </Form>
        </div>
      )
    } else if (identity === 1) {
      return (
        <div id="UpdateChild">
          <Form onSubmit={this.handleSubmit} layout='inline'>

            {/* 姓名 */}
            <Form.Item label={'姓名'}>
              {getFieldDecorator('name', {
                rules: [{ required: true, message: '姓名不能为空!' }], initialValue: childInfo.name
              })(
                <Input placeholder="姓名" />
              )}
            </Form.Item>

            {/* 校区名称 */}
            {childInfo.identity === 3 ? (
              <Form.Item label={`${custom.department || '校区'}名称`}>
                <Input placeholder={`${custom.department || '校区'}名称`} disabled value={childInfo.department.name} />
              </Form.Item>
            ) : null}


            {/* 账号 */}
            <Form.Item label={'账号'}>
              <Input placeholder="账号" disabled value={childInfo.uname} />
            </Form.Item>

            {/* 密码 */}
            <Form.Item label={'密码'}>
              {getFieldDecorator('pwd', {
                rules: [{ required: true, message: '密码不能为空!' }], initialValue: childInfo.pwd
              })(
                <Input placeholder="密码" />
              )}
            </Form.Item>

            {/* 状态 是否启用 */}
            <Form.Item label={'状态'}>
              {getFieldDecorator('used', {
                initialValue: childInfo.used
              })(
                <Select>
                  <Option value={1}>启用</Option>
                  <Option value={0}>停用</Option>
                </Select>
              )}
            </Form.Item>

            {/* 提交按钮 */}
            <Form.Item>
              <Button type="primary" htmlType="submit">确认修改</Button>
            </Form.Item>
          </Form>
        </div>
      )
    } else if (identity === 3) {
      // 当身份为校区管理员时
      return (
        <div id="UpdateChild">
          <Form onSubmit={this.handleSubmit} layout='inline'>

            {/* 姓名 */}
            <Form.Item label={'姓名'}>
              {getFieldDecorator('name', {
                rules: [{ required: true, message: '姓名不能为空!' }], initialValue: childInfo.name
              })(
                <Input placeholder="姓名" />
              )}
            </Form.Item>

            {/* 团队名称 */}
            {
              teams.length ? (<Form.Item label={`${custom.team || '团队'}名称`}>
                {getFieldDecorator('team', {
                  initialValue: childInfo.team._id
                })(
                  <Select>
                    {teams.map(item => {
                      return (<Option value={item._id} key='item._id'>{item.name}</Option>)
                    })}
                  </Select>
                )}
                {/* <Input placeholder={`${custom.team || '团队'}名称`} disabled value={childInfo.team.name} /> */}
              </Form.Item>) : null
            }

            {/* 账号 */}
            <Form.Item label={'账号'}>
              <Input placeholder="账号" disabled value={childInfo.uname} />
            </Form.Item>

            {/* 密码 */}
            <Form.Item label={'密码'}>
              {getFieldDecorator('pwd', {
                rules: [{ required: true, message: '密码不能为空!' }], initialValue: childInfo.pwd
              })(
                <Input placeholder="密码" />
              )}
            </Form.Item>

            {/* 状态 是否启用 */}
            <Form.Item label={'状态'}>
              {getFieldDecorator('used', {
                initialValue: childInfo.used
              })(
                <Select>
                  <Option value={1}>启用</Option>
                  <Option value={0}>停用</Option>
                </Select>
              )}
            </Form.Item>

            {/* 提交按钮 */}
            <Form.Item>
              <Button type="primary" htmlType="submit">确认修改</Button>
            </Form.Item>
          </Form>
        </div>
      )
    } else {
      return null
    }
  }
}
UpdateChild = Form.create({})(UpdateChild);

export default UpdateChild;