import React, { Component } from 'react';
import './index.scss'
import { Menu } from 'antd'
import { NavLink } from 'react-router-dom'

const SubMenu = Menu.SubMenu;

class SideNav extends Component {
  state = {
    nav: {
      0: [ // 超级管理员
        {
          title: '系统设置',
          navs: [
            {
              name: '用户管理',
              path: '/user/manage'
            },
            {
              name: '积分类型管理',
              path: '/integtype/manage'
            },
          ]
        },
        {
          title: '使用教程',
          navs: [
            {
              name: '使用手册',
              path: '/system/handbook'
            },
            {
              name: '视频教程',
              path: '/system/video'
            }
          ]
        }
      ],
      1: [ // root用户
        {
          title: '积分统计',
          navs: [
            {
              name: '排名查询',
              path: '/integ/ranking'
            },
          ],
        },
        {
          title: '系统设置',
          navs: [
            {
              name: '公告管理',
              path: '/notice/manage'
            },
            {
              name: `${this.props.custom.department || '校区'}管理`,
              path: '/department/manage'
            },
            {
              name: '用户管理',
              path: '/user/manage'
            },
            {
              name: '积分类型管理',
              path: '/integtype/manage'
            },
            {
              name: '积分类型模板',
              path: '/integtype/template'
            },
            {
              name: '自定义名称',
              path: '/custom'
            }
          ]
        },
        {
          title: '使用教程',
          navs: [
            {
              name: '使用手册',
              path: '/system/handbook'
            },
          ]
        }
      ],
      2: [ // 特权管理员
        {
          title: '积分管理',
          navs: [
            {
              name: '积分审核',
              path: '/integ/audit'
            },
            {
              name: '积分管理',
              path: '/integ/manage'
            },
          ],
        },
        {
          title: '使用教程',
          navs: [
            {
              name: '使用手册',
              path: '/system/handbook'
            },
          ]
        }
      ],
      3: [ // 部门管理员
        {
          title: '积分管理',
          navs: [
            {
              name: '积分加分',
              path: '/integ/add'
            },
            {
              name: '积分审核',
              path: '/integ/audit'
            },
            {
              name: '积分管理',
              path: '/integ/manage'
            },
            {
              name: '清空积分',
              path: '/integ/delete'
            },
          ],
        },
        {
          title: '统计分析',
          navs: [
            {
              name: '积分查询',
              path: '/integ/query'
            },
            {
              name: '排名查询',
              path: '/integ/ranking'
            },
            {
              name: '个人排名',
              path: '/integ/personalranking'
            },
            {
              name: `${this.props.custom.team || '团队'}排名`,
              path: '/integ/teamranking'
            },
          ],
        },
        {
          title: '系统设置',
          navs: [
            {
              name: `${this.props.custom.team || '团队'}管理`,
              path: '/team/manage'
            },
            {
              name: '积分类型管理',
              path: '/integtype/manage'
            },
            {
              name: '积分类型模板',
              path: '/integtype/template'
            },
            {
              name: '用户管理',
              path: '/user/manage'
            },
          ],
        },
        {
          title: '使用教程',
          navs: [
            {
              name: '使用手册',
              path: '/system/handbook'
            },
          ]
        }
      ],
      4: [ // 职员(教师)
        {
          title: '积分管理',
          navs: [
            {
              name: '申请积分',
              path: '/integ/apply'
            },
            {
              name: '我的积分',
              path: '/integ/myinteg'
            },
          ],
        },
        {
          title: '统计分析',
          navs: [
            {
              name: '个人排名',
              path: '/integ/personalranking'
            },
            {
              name: `${this.props.custom.team || '团队'}排名`,
              path: '/integ/teamranking'
            },
          ],
        },
        {
          title: '系统设置',
          navs: [
            {
              name: `${this.props.custom.team || '团队'}成员`,
              path: '/user/teammember'
            },
          ],
        },
        {
          title: '使用教程',
          navs: [
            {
              name: '使用手册',
              path: '/system/handbook'
            },
          ]
        }
      ],
    },
    openKeys: ['0'],
    rootSubmenuKeys: [],
  }

  onOpenChange = openKeys => {
    const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
    if (this.state.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };

  // 初始化顶级菜单的keys列表
  initRootSubmenuKeys = () => {
    const rootSubmenuKeys = []
    const len = this.state.nav[this.props.identity].length
    for (let i = 0; i < len; i++) {
      rootSubmenuKeys.push(i.toString())
    }
    this.setState({ rootSubmenuKeys })
  }

  componentDidMount() {
    // 初始化根菜单项key
    this.initRootSubmenuKeys()

  }

  render() {
    return (
      <div id="SideNav">
        <Menu
          theme={'dark'}
          mode="inline"
          openKeys={this.state.openKeys}
          onOpenChange={this.onOpenChange}
        >
          {
            Object.values(this.state.nav[this.props.identity]).map((value, index) => {
              return (
                <SubMenu key={index} title={<span>{value.title}</span>}>
                  {value.navs.map((item) => {
                    return (
                      <Menu.Item key={item.path} className='navItem'>
                        <NavLink to={item.path} className='navLink'>{item.name}</NavLink>
                      </Menu.Item>
                    )
                  })}
                </SubMenu>
              )
            })
          }
        </Menu>
      </div>
    );
  }
}

export default SideNav;