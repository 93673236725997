import React, { Component } from 'react';

// 导入第三方组件
import { Table, Icon, message, Modal, Form, Select, DatePicker, Button, Popconfirm } from 'antd'
// import { Link } from 'react-router-dom'
import OpinionModal from '../OpinionModal'

// 导入工具类
import { Host, IntegDetail, Team, User } from '../../../utils/request'

// 配置日期控件语言
import { LocaleProvider } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');

class IntegManage extends Component {
  state = {
    pages: 1, // 当前页数
    pageSize: 10, // 每页条数
    rows: 0, // 数据总条数
    custom: {}, // 自定义名称
    teamList: [], // 所有团队
    userList: [], // 所选团队的用户
    dataSource: [], // 表格数据
    columns: [], // 表头
    showPreview: false, // 显示预览框
    previewContent: '', // 预览内容
    opt: {}, // 查询参数对象
    opinionModalShow: false
  }

  preview = (path) => {
    this.setState({
      showPreview: true,
      previewContent: /\.mp4$/.test(path) ?
        (<video controls width='800' height='600'>
          <source src={Host + path}
            type="video/mp4"></source>
        </video>) :
        (<img src={Host + path} alt='' height='600' />)
    })
  }

  closePreview = () => {
    this.setState({ showPreview: false })
  }

  // 拿到自定义名称后设置表头
  initColumns = () => {
    const { custom } = this.state
    this.setState({
      columns: [
        { title: '顶级积分类型', dataIndex: 'firstType' },
        { title: '二级积分类型', dataIndex: 'secondType' },
        { title: '单项积分', dataIndex: 'singleValue' },
        { title: '申请数量', dataIndex: 'count' },
        { title: '总积分', dataIndex: 'totalValue' },
        { title: `申请人${custom.department || '校区'}`, dataIndex: 'department' },
        { title: `申请人${custom.team || '团队'}`, dataIndex: 'team' },
        { title: `申请人姓名`, dataIndex: 'apply' },
        { title: `申请时间`, dataIndex: 'createdTime' },
        { title: `审核人姓名`, dataIndex: 'audit' },
        { title: `审核时间`, dataIndex: 'auditTime' },
        { title: `备注`, dataIndex: 'remarks' },
        {
          title: '附件', dataIndex: 'attachment',
          render: (text, record) => {
            if (/\.mp4$/.test(text[0])) {
              return (
                <Icon type="video-camera" onClick={this.preview.bind(this, text[0])} title='点击查看视频' />
              )
            } else {
              return text.map(path => {
                return <img src={Host + path} key={path} width='50' onClick={this.preview.bind(this, path)} alt='' title='点击查看大图' />
              })
            }
          }
        },
        { title: `积分类型`, dataIndex: 'type' },
        { title: `审核状态`, dataIndex: 'status' },
        {
          title: '修改', dataIndex: 'manage',
          render: (text, record) => (
            // <Link to={'/integ/detail/' + record.key}>修改</Link>
            <Popconfirm title="确定作废?" okText="是" cancelText="否" onConfirm={() => this.showOpinionModal({ id: record.key, status: 0 })}>
              <span style={{ color: '#1890ff', cursor: 'pointer' }}>作废</span>
            </Popconfirm>
          )
        },
      ],
    })
  }

  showOpinionModal = (opt, type) => {
    let audit = this.audit
    this.setState({ auditOpt: opt, opinionModalShow: true, audit })
  }

  // 审核
  audit = (opt) => {
    IntegDetail.audit(opt)
      .then(res => {
        const { success, msg } = res.data
        if (success) {
          message.success('修改成功!', 3)
          this.setState({ opinionModalShow: false })
          this.getIntegList()
        } else {
          message.error(msg, 3)
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ opinionModalShow: false })
        message.error('程序错误!', 3)
      })
  }

  // 获取积分列表
  getIntegList = () => {
    const { opt, pages } = this.state
    IntegDetail.manage({ ...opt, pages })
      .then(res => {
        const { success, msg, resData, rows } = res.data
        if (success) {
          const dataSource = resData.map(item => {
            return {
              firstType: item.firstType ? item.firstType.name : '',
              secondType: item.secondType.name ? item.secondType.name : '',
              singleValue: item.singleValue,
              count: item.count,
              totalValue: item.totalValue,
              department: item.department.name,
              team: item.team.name,
              apply: item.apply ? item.apply.name : '',
              createdTime: new Date(item.createdTime).toLocaleString(),
              audit: item.audit ? item.audit.name : '',
              auditTime: item.auditTime ? new Date(item.auditTime).toLocaleString() : '',
              remarks: item.remarks,
              attachment: item.attachment,
              type: item.type ? '加分' : '申请',
              status: item.status ? item.status === 1 ? '待审' : item.status === 2 ? '已审' : '不批准' : '作废',
              key: item._id
            }
          })
          console.log(dataSource);

          this.setState({ dataSource, rows })
        } else {
          message.error(msg, 3)
        }
      })
      .catch(err => {
        console.log(err);
        message.error('积分列表获取失败!', 3)
      })
  }

  // 换页
  changePage = (pages) => {
    this.setState({ pages }, () => {
      this.getIntegList()
    })
  }

  // 获取所有团队
  getTeamList = () => {
    Team.list({ pages: 0 })
      .then(res => {
        if (res.data.success) {
          const teamList = res.data.resData.map(item => {
            return { id: item._id, name: item.name }
          })
          this.setState({ teamList })
        } else {
          message.error(res.data.msg, 3)
        }
      })
      .catch(err => { console.log(err); })
  }

  // 选择团队时 获取该团队的所有用户
  handleTeamChange = (team) => {
    this.setState({ team }, () => {
      this.props.form.resetFields(['apply'])
      this.getUserList({ team, used: true })
    })
  }

  // 根据所选团队获取用户列表
  getUserList = (opt) => {
    User.userList(opt)
      .then(res => {
        if (res.data.success) {
          const userList = res.data.resData.map(user => {
            return { name: user.name, id: user._id }
          })
          this.setState({ userList })
        } else { message.error(res.data.msg, 3) }
      })
      .catch(err => { console.log(err) })
  }

  // 当日期控件的值改变时 保存到state
  onDateChange = (date, dateString) => {
    const { opt } = this.state
    opt.startTime = dateString[0]
    opt.endTime = dateString[1]
    this.setState({ opt })
  }

  // 提交查询
  handleSubmit = (e) => {
    e.preventDefault()
    const that = this
    const { form } = this.props
    form.validateFields((err, values) => {
      if (err) {
        console.log(err);
        return message.error('程序错误!', 3)
      }

      // 时间区间
      values.startTime = this.state.opt.startTime
      values.endTime = this.state.opt.endTime

      // // 查询积分
      // that.getIntegList({ ...values, pages: 1 })

      // 把查询条件保存到state
      that.setState({ opt: values, pages: 1 }, () => {
        that.getIntegList()
      })
    })
  }

  componentDidMount() {
    const custom = window.localStorage.getItem('custom') || '{}'
    const userInfo = window.localStorage.getItem('userInfo') || '{}'
    const { identity } = JSON.parse(userInfo)
    this.setState({ custom: JSON.parse(custom), identity }, this.initColumns)
    this.getIntegList()
    this.getTeamList()
  }

  hideOpinionModal = () => {
    this.setState({ opinionModalShow: false })
  }

  render() {
    const { dataSource, columns, pageSize, pages, rows, showPreview, custom, teamList, userList, opinionModalShow, auditOpt, audit } = this.state
    const { RangePicker } = DatePicker
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 2 },
      wrapperCol: { span: 6 },
    }
    return (
      <div id='IntegManage'>
        <Form onSubmit={this.handleSubmit} layout='horizontal'>

          {/* 起止时间 */}
          <Form.Item label='起止时间' {...formItemLayout}>
            <LocaleProvider locale={zh_CN}>
              <RangePicker onChange={this.onDateChange} placeholder={['起始时间', '截止时间']} />
            </LocaleProvider>
          </Form.Item>

          {/* 选择团队 */}
          <Form.Item label={`选择${custom.team || '团队'}`} {...formItemLayout}>
            {getFieldDecorator('team', {})(
              <Select onChange={this.handleTeamChange} style={{ width: '320px' }}>
                {teamList.map(team => {
                  return <Select.Option value={team.id} key={team.id}>{team.name}</Select.Option>
                })}
              </Select>
            )}
          </Form.Item>

          {/* 选择教师 */}
          <Form.Item label={`选择${custom.user || '教师'}`} {...formItemLayout}>
            {getFieldDecorator('apply', {})(
              <Select style={{ width: '320px' }}>
                {userList.map(user => {
                  return <Select.Option value={user.id} key={user.id}>{user.name}</Select.Option>
                })}
              </Select>
            )}
          </Form.Item>

          {/* 审核类型 */}
          <Form.Item label={`审核类型`} {...formItemLayout}>
            {getFieldDecorator('auditType', {})(
              <Select style={{ width: '320px' }}>
                <Select.Option value={0}>自动</Select.Option>
                <Select.Option value={1}>手动</Select.Option>
              </Select>
            )}
          </Form.Item>

          {/* 审核状态 */}
          <Form.Item label={`审核状态`} {...formItemLayout}>
            {getFieldDecorator('status', {})(
              <Select style={{ width: '320px' }}>
                <Select.Option value={0}>作废</Select.Option>
                <Select.Option value={1}>待审</Select.Option>
                <Select.Option value={2}>已审</Select.Option>
                <Select.Option value={3}>不批准</Select.Option>
              </Select>
            )}
          </Form.Item>

          {/* 提交按钮 */}
          <Form.Item>
            <Button type="primary" htmlType="submit">提交查询</Button>
          </Form.Item>
        </Form>

        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={{
            pageSize,
            current: pages,
            total: rows,
            onChange: this.changePage,
          }}
        />

        {/* 附件预览 */}
        <Modal
          footer={null}
          width={1000}
          style={{ textAlign: 'center' }}
          onCancel={this.closePreview}
          visible={showPreview}
        >
          {this.state.previewContent}
        </Modal>

        <OpinionModal opt={auditOpt} audit={audit} show={opinionModalShow} hideOpinionModal={this.hideOpinionModal} />
      </div>
    );
  }
}

IntegManage = Form.create({})(IntegManage);

export default IntegManage;