import React, { Component } from 'react';

// 导入工具类
import { Notice, Host } from '../../utils/request'
import { message, List, Icon } from 'antd';

class Home extends Component {
  state = {
    noticeList: []
  }

  componentDidMount() {
    // 组件加载完成时 请求公告列表
    Notice.list()
      .then(res => {
        // 请求成功
        let { success, msg, resData } = res.data
        if (success) {
          // 查询成功
          if (resData.length>2) {
            resData = resData.slice(0, 3)
          }
          const noticeList = resData.map((notice, index) => {
            return notice
          })
          this.setState({ noticeList })
        } else {
          // 查询失败
          message.error(msg, 3)
        }
      })
      .catch(err => {
        // 请求错误
        console.log(err);
        message.error('程序错误!', 3)
      })
  }


  render() {
    const { noticeList } = this.state
    const description = (item) => (
      <div>
        <div className="item" style={{ float: 'left', marginRight: '20px' }}>
          <Icon type='user' style={{ marginRight: '5px' }} />
          <span>{item.author}</span>
        </div>
        <div className="item" style={{ float: 'left', marginRight: '20px' }}>
          <Icon type="calendar" style={{ marginRight: '5px' }} />
          <span>{new Date(item.publishTime).toLocaleString()}</span>
        </div>
      </div>
    )
    return (
      <div id="Home">
        <List
          itemLayout="vertical"
          size={'small'}
          dataSource={noticeList}
          header={<div>最新公告</div>}
          renderItem={item => (
            <List.Item
              key={item.title}
              extra={item.img ? (
                <img width={90} alt="公告缩略图" src={Host + item.img} />
              ) : null
              }
            >
              <List.Item.Meta
                title={item.title}
                description={description(item)}
              // description={`作者：${item.author}  发布时间：${new Date(item.publishTime).toLocaleString()}`}
              />
              <div>{item.content}</div>
            </List.Item>
          )}
        />
      </div>
    )
  }
}

export default Home;