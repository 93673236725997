import React, { Component } from 'react';

// 导入第三方组件
import { Form, Input, Button, message, Select } from 'antd'

// 导入工具类
import { IntegType, User } from '../../../utils/request'

const { Option } = Select

class IntegTypeUpdate extends Component {
  state = {
    id: this.props.match.params.id,
    info: {}
  }

  // 更新积分类型
  update = (e) => {
    e.preventDefault();
    const form = this.props.form
    form.validateFields((err, values) => {
      if (!err) {
        if (values.name.length > 22) {
          return message.warn('名称最大长度不可超过22个字符!', 3)
        }

        const id = this.state.id

        IntegType.update({ id, ...values })
          .then(res => {
            const { success, msg } = res.data

            console.log(res.data);
            if (success) {
              message.success('修改成功!', 3)
            } else {
              message.error(msg, 3)
            }
          })
          .catch(err => {
            message.error("程序错误!", 3)
          })
      }
    });
  }

  getTypeInfo(id) {
    IntegType.info({ id })
      .then(res => {
        const { success, msg, resData } = res.data
        if (success) {
          this.setState({ info: resData })
        } else {
          message.error(msg, 3)
        }
      })
      .catch(err => {
        console.log(err)
        message.error('程序错误!', 3)
      })
  }

  componentDidMount() {
    const id = this.props.match.params.id
    this.getTypeInfo(id)
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { info } = this.state
	
    return (
      <div id="IntegTypeUpdate">
        <Form onSubmit={this.update} layout='inline'>
          {/* 名称 */}
          <Form.Item>
            {getFieldDecorator('name', {
              initialValue: info.name, rules: [{ required: true, message: '名称不可为空!' }]
            })(
              <Input placeholder='积分类型名称' />
            )}
          </Form.Item>

          {/* 分数 */}
          {info.level ? (<Form.Item>
            {getFieldDecorator('value', {
              initialValue: info.value, rules: [{ required: true, message: '分数不可为空!' }]
            })(
              <Input placeholder='积分分数' />
            )}
          </Form.Item>) : null}

          {/* 审核类型 */}
          {info.level ? (<Form.Item>
            {getFieldDecorator('auditType', {
              initialValue: info.auditType
            })(
              <Select>
                <Option value={0}>自动</Option>
                <Option value={1}>手动</Option>
              </Select>
            )}
          </Form.Item>) : null}


          {/* 排序序号 */}
          <Form.Item>
            {getFieldDecorator('order', {
              initialValue: info.order, rules: [{ required: true, message: '序号不可为空!' }]
            })(
              <Input type='number' placeholder='排序序号' />
            )}
          </Form.Item>

          {/* 提交按钮 */}
          <Form.Item>
            <Button type='primary' htmlType='submit'>确认修改</Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

IntegTypeUpdate = Form.create({})(IntegTypeUpdate);

export default IntegTypeUpdate;